import { getFromLocalStorage, getFromSessionStorage, getBase64Decoded, clearItemFromSessionStorage, setToSessionStorage, setToLocalStorage } from './StorageUtils'
import { version } from './../../package.json'
import { isMobile, isTablet } from 'react-device-detect'
import { ENABLE_ANALYTICS } from '../configs/featureControlConfig'
import pageNames from '../constants/pageNames'
import SessionKeys from '../constants/sessionKeys'
import { DISPLAY_SERVICES, SERVICES } from '../constants'
import { getStationByCode } from '../services/searchPanelServices'
import getCurrentUser from '../utils/getCurrentUser'
import moment from 'moment'
import { getCountryByCode } from '../services/dataCaptureService'

const pushDLVPageView = () => {
	var dtm_event = document.createEvent('CustomEvent')
	dtm_event.initCustomEvent('aa_page_view', true, true, window.digitalData)
	document.body.dispatchEvent(dtm_event)
}

const pushDLVCustomEvent = () => {
	var dtm_event = document.createEvent('CustomEvent')
	dtm_event.initCustomEvent('aa_custom_event', true, true, window.digitalData)
	document.body.dispatchEvent(dtm_event)
}

const setUserAgentType = () => {
	var device = ''
	if (isTablet) {
		device = 'tablet'
	} else if (isMobile) {
		device = 'mobile'
	} else {
		device = 'desktop'
	}
	window.digitalData.page.pageInfo.sysEnv = device
}

export const addError = (errorCode, errorMessage) => {
	let errors = JSON.parse(sessionStorage.getItem('errors'))
	let filteredError = errors?.filter((error) => error.errorMessage !== errorMessage)
	if (errors && errors.length > 0 && filteredError.length > 0) {
		errors.push({ errorCode: errorCode, errorMessage: errorMessage })
		sessionStorage.setItem('errors', JSON.stringify(errors))
	} else {
		var list = []
		list.push({ errorCode: errorCode, errorMessage: errorMessage })
		sessionStorage.setItem('errors', JSON.stringify(list))
	}
}

const setErrors = () => {

	let errors = sessionStorage.getItem('errors')

	if (errors && JSON.parse(errors) && JSON.parse(errors).length > 0) {
		window.digitalData.page.error = JSON.parse(errors)
	}
	sessionStorage.setItem('errors', JSON.stringify([]))
}

export const setErrorsData = (errorCode, errorMessage) => {
	try {
		if (errorMessage && errorCode && window?.digitalData?.page) {
			var list = []
			list.push({ errorCode: errorCode, errorMessage: errorMessage })
			window.digitalData.page.error = list
		}
	} catch (err) {
		console.log(err)
	}
}

const setLoginDetails = async () => {
	try {
		var user = {
			loginStatus: 'anNonymous',
			agentName: '',
			agentCode: '',
			agentStation: '',
			agentCountry: '',
			languagePreference: '',

		}
		var agentData = getCurrentUser()
		if (agentData) {
			user.loginStatus = 'logged in'
			user.agentName = agentData.companyName
			user.agentCode = agentData.agentCode
			user.agentStation = agentData.stationCode
			if (user.agentStation) {
				let country = getFromLocalStorage(SessionKeys.AGENT_COUNTRY)
				if (!country) {
					const stationData = await getStationByCode(user.agentStation)
					if ( stationData && stationData.country && stationData.country !== '') {
						let countryData = await getCountryByCode(stationData.country)
						country = countryData?.countryName ? countryData?.countryName : ''
					}
					setToLocalStorage(SessionKeys.AGENT_COUNTRY, country)
				}
				user.agentCountry = country
			}
			user.languagePreference = agentData.preference ? agentData.preference.language : 'en_US'
			window.digitalData.page.pageInfo.currencyCode =
				agentData.currencyCode
		}
		window.digitalData.user = user
	} catch (err) {
		console.log(err)
	}
}

const setDigitalData = async () => {
	window.digitalData = {
		page: {
			pageInfo: {
				pageName: '',
				env: process.env.REACT_APP_ENV || 'local',
				server: 'etihadcargo.com',
				siteEdition: localStorage.getItem('i18nextLng')
					? localStorage.getItem('i18nextLng')
					: 'en',
				sysEnv: '',
				version: version,
				language: localStorage.getItem('i18nextLng')
					? localStorage.getItem('i18nextLng')
					: 'en',
				flowType: 'booking',
				currencyCode: '',
			},
			category: {
				siteSection: 'eycargo',
				primaryCategory: '',
				subCategory1: '',
				subCategory2: '',
			},
			error: [],
		},
		user: {},
	}
	await setLoginDetails()

	setErrors()
	setUserAgentType()
}


export const updatePromoCodeError = (errorCode, errorMessage) => {
	try {
		if (errorMessage && errorCode && window?.digitalData?.page) {
			var list = []
			list.push({ errorCode: errorCode, errorMessage: errorMessage })
			window.digitalData.page.error = list
		}
	} catch (err) {
		console.log(err)
	}

}

export const updateDigitaldataFlightSearch = (pageName, primaryCategory, subCategory1, subCategory2) => {
	try {
		setDigitalData()
		if (window?.digitalData?.page?.pageInfo) {
			window.digitalData.page.pageInfo.pageName = pageName
			window.digitalData.page.category.primaryCategory = primaryCategory
			window.digitalData.page.category.subCategory1 = subCategory1
			if (subCategory2) {
				window.digitalData.page.category.subCategory2 = subCategory2
			}
		}
		let data = getBase64Decoded(getFromSessionStorage('searchObject'))
		let quote = getFromSessionStorage('quotationId')
		let quoteName = getFromSessionStorage('quoteName')
		let template = getFromSessionStorage('templateSelected')
		let draft = getFromSessionStorage('draftSelected')
		let upsellOffered = getFromSessionStorage('upsellOffered')
		let sortOption = getFromSessionStorage('sortOption')

		let info = {
			product: {
				productInfo: {
					origin: data.origin.code,
					destination: data.destination.code,
					routing: data.origin.code + ":" + data.destination.code,
					upsellOffered: upsellOffered ? 'Yes' : upsellOffered == null || upsellOffered == '' || upsellOffered == 0 ? 'No' : 'No',
					upsellClicked: data.appliedServices.length !== 0 ? 'Yes' : 'No',
					upsellService: data.appliedServices.length !== 0 ? DISPLAY_SERVICES[SERVICES.FAST_TRACK] : '',
					commodity: data.shippingItemCode.code,
					productName: data.shippingItemCode.product,
					scc: data.sccCodes.map(x => x.scc).join(','),
					shippingDate: data.shippingDate,
					arrivalDate: data.arrivalDate ? data.arrivalDate : '',
					cargoType: data.shippingType,
					stackability: (data.isAllStackable) ? "Yes" : "No",
					grossWeight: data.totalWeightInKg + ' kg',
					grossWeightValue: data.totalWeightInKg + '',
					volume: data.totalVolumeInMtrs + ' CBM',
					volumeValue: data.totalVolumeInMtrs + '',
					unitofWeight: "kg",
					unitofVolume: "cbm",
					promocode: data.promoCode ? data.promoCode : '',
					spotID: data.spotRateCode ? data.spotRateCode : '',
					quoteID: quoteName ? quoteName : '',
					templateName: template ? template : '',
					draftID: draft ? draft : '',
					promoError: '',
					overflow: data.mixLooseDetails[0].weight ? 'Yes' : 'No',
					searchType: template !== null ? 'template' : draft !== null ? 'draft' : 'search'
				},
				flightDetails: {
					flightAvailability: 'No',
					rateAvailability: 'No',
					rateType: '',
					instantConfirmation: data.isInstantConfirmation ? data.isInstantConfirmation : 'No',
					upsellOffered: upsellOffered ? 'Yes' : upsellOffered == null || upsellOffered == '' || upsellOffered == 0 ? 'No' : 'No',
					upsellClicked: data.appliedServices.length !== 0 ? 'Yes' : 'No',
					upsellService: data.appliedServices.length !== 0 ? DISPLAY_SERVICES[SERVICES.FAST_TRACK] : '',
					filtersUsed: 'Nofilter',
					sortOptions: 'cheapest',
					flightSelection: 'No',
					itenerayError: ''
				},
			},
		}
		window.digitalData.product = info.product;
	} catch (err) {
		console.log(err)
	}
}

export const updateFlightSearchResultsData = (flightsList, request) => {

	try {
		let data = getBase64Decoded(getFromSessionStorage('searchObject'))
		let upsellOffered = getFromSessionStorage('upsellOffered')
		let flightUpsellOffered = false
		if (window?.digitalData?.product?.flightDetails) {
			let flightDetails = window.digitalData.product.flightDetails
			let defaultFlights = flightsList && flightsList.DEFAULT ? flightsList.DEFAULT : null
			let XPSFlights = flightsList && flightsList.XPS ? flightsList.DEFAULT : null
			if (defaultFlights && defaultFlights.flightItineraryList && defaultFlights.flightItineraryList.length > 0) {
				let rateTypes = []
				let instantConfirmations = []
				defaultFlights.flightItineraryList.forEach((item) => {
					if (item.priceClass && !rateTypes.includes(item.priceClass)) {
						rateTypes.push(item.priceClass)
					}
					const isHideRate = (item.isForceQueued ||
						item.overHangShipment ||
						item.bigShipment)
						? true
						: false

					if (item.ratingDetails[0].totalRate &&
						item.ratingDetails[0].totalRate !== 0 &&
						!isHideRate) {
						if (item.overBookingAllowed ||
							item.overHangShipment ||
							item.bigShipment ||
							item.isForceQueued) {
							instantConfirmations.push(false)
						} else {
							instantConfirmations.push(true)
						}
					} else {
						instantConfirmations.push(false)
					}
				})
				const rateTypesVal = rateTypes.join("|");
				flightDetails.rateType = rateTypesVal
				const instantConfirmation = instantConfirmations.includes(true) ? 'Yes' : 'No'
				flightDetails.instantConfirmation = instantConfirmation
				flightDetails.itenerayError = ''
				window.digitalData.page.error = []
				sessionStorage.setItem('errors', JSON.stringify([]))
				pushEventInstantConfirmation(instantConfirmation)
				flightDetails.flightAvailability = 'Yes'
			} else {
				flightDetails.flightAvailability = 'No'
				flightDetails.instantConfirmation = 'No'
				flightDetails.rateType = ''
				pushEventInstantConfirmation('No')
			}
			if (defaultFlights && defaultFlights.lowestRate) {
				if (defaultFlights.lowestRate.message && defaultFlights.lowestRate.message === 'No rates available') {
					flightDetails.rateAvailability = 'No'
				} else if (defaultFlights.lowestRate.amount) {
					flightDetails.rateAvailability = 'Yes'
				}
			} else {
				flightDetails.rateAvailability = 'No'
			}
			flightUpsellOffered = XPSFlights && XPSFlights.flightItineraryList && XPSFlights.flightItineraryList.length > 0 ? true : false
			setToSessionStorage('flightUpsellOffered', flightUpsellOffered ? 'Yes' : 'No')
			flightDetails.upsellOffered = flightUpsellOffered ? 'Yes' : upsellOffered ? 'Yes' : 'No'
			flightDetails.upsellService = flightUpsellOffered ? DISPLAY_SERVICES[SERVICES.FAST_TRACK] : data.appliedServices.length !== 0 ? DISPLAY_SERVICES[SERVICES.FAST_TRACK] : ''
			flightDetails.upsellClicked = data.appliedServices.length !== 0 ? 'Yes' : 'No'

			if (window?.digitalData?.product?.productInfo) {
				let productInfo = window.digitalData.product.productInfo
				productInfo.upsellOffered = flightUpsellOffered ? 'Yes' : upsellOffered ? 'Yes' : 'No'
				productInfo.upsellService = flightUpsellOffered ? DISPLAY_SERVICES[SERVICES.FAST_TRACK] : data.appliedServices.length !== 0 ? DISPLAY_SERVICES[SERVICES.FAST_TRACK] : ''
				productInfo.upsellClicked = data.appliedServices.length !== 0 ? 'Yes' : 'No'
				if (request && request.shippingDate) {
					productInfo.shippingDate = request.shippingDate
				}
				window.digitalData.product.productInfo = productInfo
			}
			window.digitalData.product.flightDetails = flightDetails
			if (ENABLE_ANALYTICS) {
				setTimeout(() => {
					pushDLVPageView()
				}, 500);
			}
		}
	} catch (err) {
		console.log(err)
	}

}

export const updateFlightSearchFilterData = (filterUsed) => {
	try {
		if (window?.digitalData?.product?.flightDetails) {
			let flightDetails = window.digitalData.product.flightDetails
			if (filterUsed) {
				const filterDetails = getFilters(filterUsed);
				flightDetails.filtersUsed = filterDetails
				window.digitalData.product.flightDetails = flightDetails
			}
		}
	} catch (err) {
		console.log(err)
	}
}

const getFilters = (filterUsed) => {
	let filters = []
	if (filterUsed) {
		if (filterUsed.duration && filterUsed.duration !== 0) {
			filters.push('duration')
		}
		if (filterUsed.stops == '0' ||
			filterUsed.stops == '1' || filterUsed.stops == '2+') {
			filters.push('no of stops')
		}
		if (filterUsed?.aircraftClassification?.freighter == true || filterUsed?.aircraftClassification?.narrowBody == true ||
			filterUsed?.aircraftClassification?.truck == true || filterUsed?.aircraftClassification?.wideBody == true) {
			filters.push('classification')
		}
	}


	return filters.length > 0 ? filters.join("|") : 'Nofilter';
}

export const updateFlightSearchSortingData = (sortOption) => {
	try {
		if (window?.digitalData?.product?.flightDetails) {
			let flightDetails = window.digitalData.product.flightDetails
			if (sortOption) {
				flightDetails.sortOptions = sortOption ? sortOption : 'cheapest',
					window.digitalData.product.flightDetails = flightDetails
			}
		}
	} catch (err) {
		console.log(err)
	}
}

const updateDigitaldataBookingSummary = () => {

	let selectedFlight = getBase64Decoded(getFromSessionStorage('flightSelected'))
	let isHideRate = (selectedFlight.isForceQueued || selectedFlight.overHangShipment || selectedFlight.bigShipment) ? true : false
	let quataionDetails = getBase64Decoded(getFromSessionStorage('quotationDetails'))
	let data = getBase64Decoded(getFromSessionStorage('searchObject'))
	let quote = getFromSessionStorage('quotationId')
	let quoteName = getFromSessionStorage('quoteName')
	let template = getFromSessionStorage('templateSelected')
	let draft = getFromSessionStorage('draftSelected')
	let upsellOffered = getFromSessionStorage('upsellOffered')
	let flightUpsellOffered = getFromSessionStorage('flightUpsellOffered')

	let sortOption = getFromSessionStorage('sortOption')
	let filterUsed = getBase64Decoded(getFromSessionStorage('selectedfilter'))

	let digitaldataBookingSummary = {
		product: {
			productInfo: {
				origin: data.origin.code,
				destination: data.destination.code,
				routing: data.origin.code + ":" + data.destination.code,
				upsellOffered: flightUpsellOffered && flightUpsellOffered === 'Yes' ? 'Yes' : upsellOffered ? 'Yes' : 'No',
				upsellService: flightUpsellOffered && flightUpsellOffered === 'Yes' ? DISPLAY_SERVICES[SERVICES.FAST_TRACK] : data.appliedServices.length !== 0 ? DISPLAY_SERVICES[SERVICES.FAST_TRACK] : '',
				upsellClicked: selectedFlight.service && selectedFlight.service == SERVICES.FAST_TRACK ? 'Yes' : data.appliedServices.length !== 0 ? 'Yes' : 'No',
				commodity: data.shippingItemCode.code,
				productName: data.shippingItemCode.product,
				scc: data.sccCodes.map(x => x.scc).join(','),
				shippingDate: selectedFlight.departureDateTime ?  moment(selectedFlight.departureDateTime, 'DD-MMM-YYYY').format('DD-MMM-YYYY') : data.shippingDate,
				arrivalDate: selectedFlight.arrivalDateTime,
				cargoType: data.shippingType,
				stackability: (data.isAllStackable) ? "Yes" : "No",
				grossWeight: data.totalWeightInKg + 'kg',
				grossWeightValue: data.totalWeightInKg + '',
				volume: data.totalVolumeInMtrs + 'CBM',
				volumeValue: data.totalVolumeInMtrs + '',
				unitofWeight: "kg",
				unitofVolume: "cbm",
				promocode: data.promoCode ? data.promoCode : '',
				spotID: data.spotRateCode ? data.spotRateCode : '',
				quoteID: quoteName ? quoteName : '',
				templateName: template ? template : '',
				draftID: draft ? draft : '',
				promoError: '',
				overflow: data.mixLooseDetails[0].weight ? 'Yes' : 'No',
				searchType: template !== null ? 'template' : draft !== null ? 'draft' : 'search'
			},
			flightDetails: {
				flightAvailability: selectedFlight !== null || selectedFlight !== '' ? 'Yes' : 'No',
				rateAvailability: selectedFlight.ratingDetails[0].totalRate && selectedFlight.ratingDetails[0].totalRate !== 0 &&
					!isHideRate ? 'Yes' : 'No',
				rateType: selectedFlight.priceClass ? selectedFlight.priceClass : '',
				instantConfirmation: (selectedFlight.overBookingAllowed ||
					selectedFlight.overHangShipment ||
					selectedFlight.bigShipment ||
					selectedFlight.isForceQueued) ? 'No' : 'Yes',
				upsellOffered: flightUpsellOffered && flightUpsellOffered === 'Yes' ? 'Yes' : upsellOffered ? 'Yes' : 'No',
				upsellService: flightUpsellOffered && flightUpsellOffered === 'Yes' ? DISPLAY_SERVICES[SERVICES.FAST_TRACK] : data.appliedServices.length !== 0 ? DISPLAY_SERVICES[SERVICES.FAST_TRACK] : '',
				upsellClicked: selectedFlight.service && selectedFlight.service == SERVICES.FAST_TRACK ? 'Yes' : data.appliedServices.length !== 0 ? 'Yes' : 'No',
				filtersUsed: getFilters(filterUsed),
				sortOptions: sortOption ? sortOption : 'cheapest',
				flightSelection: 'Yes',
				itenerayError: ''
			},
		},
		booking: {
			bookingDetails: {
				chargeableWeight: data.chargeableWeight + 'kg',
				routing: selectedFlight.flightSegmentList[0].directFlight == 'N' ? selectedFlight.flightSegmentList[0].segmentOrigin + '-' + selectedFlight.flightSegmentList.map(x => x.segmentDestination).join('-') : selectedFlight.origin + ':' + selectedFlight.destination,
				transitTime: selectedFlight.cumulativeJourneyTime + ' hrs',
				rateAvailability: selectedFlight.ratingDetails[0].totalRate && selectedFlight.ratingDetails[0].totalRate !== 0 &&
					!isHideRate ? 'Yes' : 'No',
				spotrateRequest: 'No',
				spotrateAll_In: 'No',
				bookNowClick: 'No',
				bookingError: selectedFlight.warningMessages,
				quoteRaised: (quataionDetails && quataionDetails.quotationType === 'saved') ? 'Yes' : 'No',
				newquoteID: quataionDetails ? quataionDetails.quotationId : ''
			},
		}
	}
	if (digitaldataBookingSummary.product.flightDetails.filtersUsed == '||') {
		digitaldataBookingSummary.product.flightDetails.filtersUsed = ''
	}
	if (digitaldataBookingSummary.product.flightDetails.filtersUsed == 'Duration||classification') {
		digitaldataBookingSummary.product.flightDetails.filtersUsed = 'Duration|classification'
	}
	window.digitalData.booking = digitaldataBookingSummary.booking;
	window.digitalData.product = digitaldataBookingSummary.product;

}
const updateDigitaldataBookingConfirmation = (data, qres, pickAllinRate, spotRate) => {
	let details = getBase64Decoded(getFromSessionStorage('searchObject'))
	let selectedFlight = getBase64Decoded(getFromSessionStorage('flightSelected'))
	let isHideRate = (selectedFlight.isForceQueued || selectedFlight.overHangShipment || selectedFlight.bigShipment) ? true : false
	let quataionDetails_ = getBase64Decoded(getFromSessionStorage('quotationDetails'))
	let quote = getFromSessionStorage('quotationId')
	let quoteName = getFromSessionStorage('quoteName')
	let template = getFromSessionStorage('templateSelected')
	let draft = getFromSessionStorage('draftSelected')
	//let spotRate = getFromSessionStorage('spotRate')
	//let pickAllinRate = getFromSessionStorage('allInRate')
	let upsellOffered = getFromSessionStorage('upsellOffered')
	let sortOption = getFromSessionStorage('sortOption')
	let filterUsed = getBase64Decoded(getFromSessionStorage('selectedfilter'))
	let flightUpsellOffered = getFromSessionStorage('flightUpsellOffered')

	let digitalDataBookingConfirmation = {
		product: {
			productInfo: {
				origin: details.origin.code,
				destination: details.destination.code,
				routing: details.origin.code + ":" + details.destination.code,
				upsellOffered: flightUpsellOffered && flightUpsellOffered === 'Yes' ? 'Yes' : upsellOffered ? 'Yes' : 'No',
				upsellService: flightUpsellOffered && flightUpsellOffered === 'Yes' ? DISPLAY_SERVICES[SERVICES.FAST_TRACK] : details.appliedServices.length !== 0 ? DISPLAY_SERVICES[SERVICES.FAST_TRACK] : '',
				upsellClicked: selectedFlight.service && selectedFlight.service == SERVICES.FAST_TRACK ? 'Yes' : details.appliedServices.length !== 0 ? 'Yes' : 'No',
				commodity: details.shippingItemCode.code,
				productName: details.shippingItemCode.product,
				scc: details.sccCodes.map(x => x.scc).join(','),
				shippingDate: selectedFlight.departureDateTime ?  moment(selectedFlight.departureDateTime, 'DD-MMM-YYYY').format('DD-MMM-YYYY') : data.shippingDate,
				arrivalDate: selectedFlight.arrivalDateTime,
				cargoType: details.shippingType,
				stackability: (details.isAllStackable) ? "Yes" : "No",
				grossWeight: details.totalWeightInKg + 'kg',
				grossWeightValue: details.totalWeightInKg + '',
				volume: details.totalVolumeInMtrs + 'CBM',
				volumeValue: details.totalVolumeInMtrs + '',
				unitofWeight: "kg",
				unitofVolume: "cbm",
				promocode: details.promoCode ? details.promoCode : '',
				spotID: details.spotRateCode ? details.spotRateCode : '',
				quoteID: quoteName ? quoteName : '',
				templateName: template ? template : '',
				draftID: draft ? draft : '',
				promoError: '',
				overflow: details.mixLooseDetails[0].weight ? 'Yes' : 'No',
				searchType: template !== null ? 'template' : draft !== null ? 'draft' : 'search'
			},
			flightDetails: {
				flightAvailability: selectedFlight !== null || selectedFlight !== '' ? 'Yes' : 'No',
				rateAvailability: selectedFlight.ratingDetails[0].totalRate &&
					selectedFlight.ratingDetails[0].totalRate !== 0 &&
					!isHideRate ? 'Yes' : 'No',
				rateType: selectedFlight.priceClass ? selectedFlight.priceClass : '',
				instantConfirmation: (selectedFlight.overBookingAllowed ||
					selectedFlight.overHangShipment ||
					selectedFlight.bigShipment ||
					selectedFlight.isForceQueued) ? 'No' : 'Yes',
				upsellOffered: flightUpsellOffered && flightUpsellOffered === 'Yes' ? 'Yes' : upsellOffered ? 'Yes' : 'No',
				upsellService: flightUpsellOffered && flightUpsellOffered === 'Yes' ? DISPLAY_SERVICES[SERVICES.FAST_TRACK] : details.appliedServices.length ? DISPLAY_SERVICES[SERVICES.FAST_TRACK] : '',
				upsellClicked: selectedFlight.service && selectedFlight.service == SERVICES.FAST_TRACK ? 'Yes' : details.appliedServices.length !== 0 ? 'Yes' : 'No',
				filtersUsed: getFilters(filterUsed),
				sortOptions: sortOption ? sortOption : 'cheapest',
				flightSelection: 'Yes',
				itenerayError: ''
			},
		},
		booking: {
			bookingDetails: {
				chargeableWeight: details.chargeableWeight + 'kg',
				routing: selectedFlight.flightSegmentList[0].directFlight == 'N' ? selectedFlight.flightSegmentList[0].segmentOrigin + '-' + selectedFlight.flightSegmentList.map(x => x.segmentDestination).join('-') : selectedFlight.origin + ':' + selectedFlight.destination,
				transitTime: selectedFlight.cumulativeJourneyTime + ' hrs',
				rateAvailability: selectedFlight.ratingDetails[0].totalRate &&
					selectedFlight.ratingDetails[0].totalRate !== 0 &&
					!isHideRate ? 'Yes' : 'No',
				spotrateRequest: spotRate ? 'Yes' : 'No',
				spotrateAll_In: pickAllinRate == 'Y' ? 'Yes' : 'No',
				bookNowClick: 'Yes',
				bookingError: selectedFlight.warningMessages,
				quoteRaised: (quataionDetails_ && quataionDetails_.quotationType === 'saved') ? 'Yes' : 'No',
				newquoteID: quataionDetails_ ? quataionDetails_.quotationId : ''
			},
			bookingConfirmation: {
				AWBnumber: data.shipmentPrefix + '-' + data.masterDocumentNumber,
				BookingStatus: data.bookingStatus == 'C' ? 'Confirmed' : data.bookingStatus == 'Q' ? 'Queued' : data.bookingStatus == 'X' ? 'Cancelled' : data.bookingStatus == 'R' ? 'Rejected' : 'Waitlisted',
				CargoType: details.shippingType,
				queueReason: qres,
			}
		}
	}
	if (digitalDataBookingConfirmation.product.flightDetails.filtersUsed == '||') {
		digitalDataBookingConfirmation.product.flightDetails.filtersUsed = ''
	}
	if (digitalDataBookingConfirmation.product.flightDetails.filtersUsed == 'Duration||classification') {
		digitalDataBookingConfirmation.product.flightDetails.filtersUsed = 'Duration|classification'
	}
	window.digitalData.booking = digitalDataBookingConfirmation.booking;
	window.digitalData.product = digitalDataBookingConfirmation.product
}
const updateDigitaldaFlightEnquiry = (request) => {
	if (request && request != null) {
		let digitaldaFlightEnquiry = {
			event: {
				eventName: "Flight Enquiry Search",
				eventType: "flight_enquiry_search",
				eventAction: 'search_click'
			},
			searchInput: {
				searchInputinfo: {
					Origin: request.origin,
					Destination: request.destination,
					flightDate: request.flightDate,
					flightNumber: request.flightNumber,
					flightType: request.flightType == "CO" ? 'passenger' : request.flightType == "C" ? 'freighter' : request.flightType == 'T,ET,IT' ? 'truck' : 'All',
					aircraftClassification: request.aircraftClassification == 'W' ? 'widebody' : request.aircraftClassification == 'N' ? 'narrowbody' : 'All',
					searchResultsDisplayed: ''
				}
			}
		}
		window.digitalData.event = digitaldaFlightEnquiry.event,
			window.digitalData.searchInput = digitaldaFlightEnquiry.searchInput
	}
}
export const pushEventBookingSearch = (data) => {

	let datalist = getBase64Decoded(getFromSessionStorage('bookingSearchFormData'))

	let digitaldataListBooking = {
		event: {
			eventName: "Flight Enquiry Search",
			eventType: "flight_enquiry_search ",
			eventAction: "search_click"
		},
		searchInput: {
			mybookings: {
				shipping_from: datalist?.origin?.code,
				shipping_t0: datalist?.destination ? datalist?.destination : '',
				bookingFromDate: datalist?.fromBookingDate,
				bookingToDate: datalist?.toBookingDate,
				bookingStatus: datalist?.bookingStatus ? datalist?.bookingStatus : '',
				product: datalist?.product?.name,
				AWBnumber: datalist?.shipmentIdentifierDetails?.shipmentPrefix + datalist?.shipmentIdentifierDetails?.masterDocumentNumber,
				flightNumber: datalist?.flightNumber,
				flightDate: datalist?.flightDate ? datalist?.flightDate : '',
				bookingList: ""
			}
		}

	}
	window.digitalData.mybookings = digitaldataListBooking.searchInput.mybookings;
	window.digitalData.event = digitaldataListBooking.event;
	pushDLVCustomEvent();
	clearItemFromSessionStorage(SessionKeys.MANAGE_BOOKING_SEARCH_INFO)
}


export const pushEventBookingDashboard = (values) => {
	window.digitalData = {
		event: {
			eventName: values,
			eventType: "dashboard_radio_button_click",
			eventAction: "radio button"
		}

	}

	pushDLVCustomEvent();
}

export const pushEventFlightSearchError = (errorCode, errorMessage, request) => {
	try {
		window.digitalData.event = {
			eventInfo: {
				eventName: "flightsearch_error",
				eventType: "error",
			},
			attributes: {
				errorMessage: errorMessage,
				errorCode: errorCode
			}
		}

		if (window?.digitalData?.product?.flightDetails) {
			let flightDetails = window.digitalData.product.flightDetails
			flightDetails.itenerayError = errorMessage
			flightDetails.flightAvailability = 'No'
			flightDetails.instantConfirmation = 'No'
			flightDetails.rateType = ''
			flightDetails.rateAvailability = 'No'
			window.digitalData.product.flightDetails = flightDetails
			clearItemFromSessionStorage('flightUpsellOffered')
		}

		if (window?.digitalData?.product?.productInfo) {
			let productInfo = window.digitalData.product.productInfo
			if (request && request.shippingDate) {
				productInfo.shippingDate = request.shippingDate
			}
			window.digitalData.product.productInfo = productInfo
		}
		var list = []
		list.push({ errorCode: errorCode, errorMessage: errorMessage })
		window.digitalData.page.error = list

		if (ENABLE_ANALYTICS) {
			setTimeout(() => {
				pushDLVCustomEvent();
			}, 500);
		}
	} catch (err) {
		console.log("catch error", err)
	}

}

export const pushEventFlightUnexpectedError = (errorCode, errorMessage, request) => {

	try {

		window.digitalData.event = {
			eventInfo: {
				eventName: "flightunexpected_error",
				eventType: "error",
			},
			attributes: {
				errorMessage: errorMessage,
				errorCode: errorCode
			}
		}
		if (window?.digitalData?.product?.flightDetails) {
			let flightDetails = window.digitalData.product.flightDetails
			flightDetails.itenerayError = errorMessage
			flightDetails.flightAvailability = 'No'
			flightDetails.instantConfirmation = 'No'
			flightDetails.rateType = ''
			flightDetails.rateAvailability = 'No'
			window.digitalData.product.flightDetails = flightDetails
			clearItemFromSessionStorage('flightUpsellOffered')
		}

		if (window?.digitalData?.product?.productInfo) {
			let productInfo = window.digitalData.product.productInfo
			if (request && request.shippingDate) {
				productInfo.shippingDate = request.shippingDate
			}
			window.digitalData.product.productInfo = productInfo
		}
		var list = []
		list.push({ errorCode: errorCode, errorMessage: errorMessage })
		window.digitalData.page.error = list

		if (ENABLE_ANALYTICS) {
			setTimeout(() => {
				pushDLVCustomEvent();
			}, 500);
		}
	} catch (err) {
		console.log("catch error", err)
	}

}

export const pushEventInstantConfirmation = (value) => {
	window.digitalData.event = {
		eventInfo: {
			eventName: "instantconfirmation",
			eventType: "confirmationstatus",
		},
		attributes: {
			instantConfirmation: value
		}
	}

	pushDLVCustomEvent();
}

export const pushEventDashboardBookingandTracking = (eventName) => {
	window.digitalData = {
		event: {
			eventName: eventName,
			eventType: "book_Now_track_click",
			eventAction: "dashboard button click"
		}
	}

	pushDLVCustomEvent();
}

export const pushEventBookingTime = (timevalues) => {
	window.digitalData = {
		event: {
			eventName: timevalues,
			eventType: "airway_toggle_button ",
			eventAction: "airway toggle button",
		}
	}

	pushDLVCustomEvent();

}
export const pushEventDashboardHeaders = (headers) => {
	// debugger
	window.digitalData = {
		event: {
			eventName: headers,
			eventType: "header navigation link",
			eventAction: "dashboard_navigation"
		}
	}

	pushDLVCustomEvent();
}



export const pushPageData = async (pageName, primaryCategory, subCategory1, subCategory2, subCategory3, data = null, qres = null, pickAllinRate = null, spotRate = null) => {
	if (!ENABLE_ANALYTICS) {
		return false
	}
	await setDigitalData()
	window.digitalData.page.pageInfo.pageName = pageName
	window.digitalData.page.category.primaryCategory = primaryCategory
	window.digitalData.page.category.subCategory1 = subCategory1
	if (subCategory2) {
		window.digitalData.page.category.subCategory2 = subCategory2
	}
	if (subCategory3) {
		window.digitalData.page.category.subCategory3 = subCategory3
	}

	try {
		//FlightList page
		if (pageName == pageNames.CHOOSE_FLIGHT) {
			updateDigitaldataFlightSearch()
		}
		//BookingSummary page
		if (pageName == pageNames.BOOKING_SUMMARY) {
			updateDigitaldataBookingSummary()
		}
		//Booking confirmation page
		if (pageName == pageNames.BOOKING_CONFIRMATION) {
			updateDigitaldataBookingConfirmation(data, qres, pickAllinRate, spotRate)
		}
		//Flight Enquiry Page – Search Click
		if (pageName == pageNames.FLIGHT_ENQUIRY) {
			updateDigitaldaFlightEnquiry(data)
		}
		if (pageName == pageNames.LIST_BOOKING) {
			//updateDigitaldataListBooking()
		}
		//pushDLVPageView()
		if (pageName == pageNames.DASHOARD) {
			//updatedigitalDataBookingDashboard(chart)
			//pushEventBookingTime(headers)
			setTimeout(() => {
				pushDLVPageView()
			}, 1000);
		} else {
			pushDLVPageView()
		}
	}
	catch (err) {
		console.log("catch error", err)
	}
}







