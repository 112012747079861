import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import React, { useEffect, useCallback, useState } from 'react'
import trashIcon from '../../assets/images/trash.svg'
import infoIcon from '../../assets/images/info_icon.svg'
import {
	DETAILS_APPLY_FOR,
	DIMENSION_UNIT,
	DIMENSION_UNIT_SELECT_ITEMS,
	FORM_FIELDS,
	getRoundedValue,
	LOOSE_FORM_FIELDS,
	WEIGHT_UNIT_SELECT_ITEMS,
} from '../../constants'
import calculateKgWeight from '../../utils/calculateKgWeight'
import { validateLooseDetails, validateLineItemWeights } from '../SearchPanel/formUtils'
import { validateTotalWeight } from '../SearchPanel/formUtils'
import { validateOperationalWeight } from '../SearchPanel/formUtils'
import './item-form.scss'
import ExportToExcel from '../LooseLineItem/Content'
import ExcelReader from '../LooseLineItem/LooseUpload'
import envConfigs from '../../configs/envConfigs'
import MESSAGES from '../../constants/Messages'
import { useTranslation } from 'react-i18next'
import {
	calculateWeightFromKg,
	calculateWeightToKg,
	round,
	allowNumberOnly,
} from '../../utils/common'
//import infoIcon from '../../assets/images/info_icon.svg'
const LooseItemForm = ({
	looseDetails,
	setLooseDetails,
	totalWeight,
	setTotalWeight,
	setLooseItemsGrossWeight,
	weightUnit,
	setWeightUnit,
	dimensionUnit,
	setDimensionUnit,
	isAllStackable,
	setAllStackable,
	isAllTiltable,
	setAllTiltable,
	handleAddLineItem,
	setTotalPieces,
	error,
	setError,
	setTotalVolumeInMeters,
	setTotalWeightInKg,
	totalWeightInKilo,
	totalVolumeinMtrs,
	setChargeableWeight,
	setChargeableWeightInSelectedUnit,
	calculatedTotalWeight,
	setCalculatedTotalWeight,
	setActualVolume,
	setActualChargeableWeight,
	setActualGrossWeight,
	actualTotalIneItemWeight,
	setActualTotalIneItemWeight,
	draftCallback,
	adjustedWeight,
	setAdjustedWeight,
	showOpWeight,
	setShowOpweight,
	isHideReprocessComponents,
	setDisableButton
}) => {
	const { t, i18n } = useTranslation(['labels', 'errors'])



	const [adjustedWeightInKg, setAdjustedWeightInKg] = useState(0)

	useEffect(() => {
		let volumetricWeight = 0
		let chargeableWeight
		let chargeableWeightInSelectedUnit
		if (totalVolumeinMtrs) {
			//volumetricWeight = round(totalVolumeinMtrs, 2) * 166.6667
			volumetricWeight = Number(totalVolumeinMtrs).toFixed(2) * 166.6667
		}

		if (totalWeightInKilo || volumetricWeight || adjustedWeightInKg) {
			if (Number(totalWeightInKilo) >= Number(volumetricWeight)) {
				if (
					adjustedWeightInKg &&
					Number(totalWeightInKilo) <= Number(adjustedWeightInKg)
				) {
					chargeableWeight = getRoundedValue(Number(adjustedWeightInKg))
					chargeableWeightInSelectedUnit = adjustedWeight
				} else {
					chargeableWeight = Number(totalWeightInKilo)

					if (totalWeight) {
						chargeableWeightInSelectedUnit = totalWeight
					} else {
						chargeableWeightInSelectedUnit = actualTotalIneItemWeight
					}
				}
			} else {
				if (
					adjustedWeightInKg &&
					Number(totalWeightInKilo) <= Number(adjustedWeightInKg) &&
					Number(volumetricWeight) <= Number(adjustedWeightInKg)
				) {
					chargeableWeight = getRoundedValue(Number(adjustedWeightInKg))
					chargeableWeightInSelectedUnit = adjustedWeight
				} else {
					chargeableWeight = getRoundedValue(Number(volumetricWeight))
					chargeableWeightInSelectedUnit = calculateWeightFromKg(
						chargeableWeight,
						weightUnit
					)
				}
			}
			setChargeableWeight(chargeableWeight)
			setChargeableWeightInSelectedUnit(chargeableWeightInSelectedUnit)
		}
	}, [totalVolumeinMtrs, totalWeightInKilo, totalWeight, adjustedWeightInKg])


	// useEffect(() => {
	// 	console.log("triggeredstackable")
	// 	if (isAllStackable) {
	// 		setLooseDetails(
	// 			looseDetails.map((item) => ({ ...item, stackable: true }))
	// 		)
	// 	} else {
	// 		setLooseDetails(
	// 			looseDetails.map((item) => ({ ...item, stackable: false }))
	// 		)
	// 	}
	// }, [isAllStackable])
	// useEffect(() => {
	// 	console.log("triggeredTiltable")
	// 	if (isAllTiltable) {
	// 		setLooseDetails(looseDetails.map((item) => ({ ...item, turnable: true })))
	// 	} else {
	// 		setLooseDetails(
	// 			looseDetails.map((item) => ({ ...item, turnable: false }))
	// 		)
	// 	}
	// }, [isAllTiltable])

	useEffect(() => {
		handleSummaryChange(looseDetails)
		handleWeightInput(looseDetails)
	}, [looseDetails, weightUnit, dimensionUnit, totalWeight])

	useEffect(() => {
		if (totalWeight) {
			setActualGrossWeight(totalWeight)
			const totalWeightinKg = calculateWeightToKg(totalWeight, weightUnit)
			setTotalWeightInKg(totalWeightinKg)
		} else {
			setActualGrossWeight(calculatedTotalWeight)
			const calculatedTotalWeightInKg = calculateWeightToKg(
				calculatedTotalWeight,
				weightUnit
			)
			setLooseItemsGrossWeight(calculatedTotalWeightInKg)
			setTotalWeightInKg(calculatedTotalWeightInKg)
		}
		if (adjustedWeight) {
			const operationalWeightInKg = calculateWeightToKg(
				adjustedWeight,
				weightUnit
			)
			setAdjustedWeightInKg(operationalWeightInKg)
		}
	}, [totalWeight, calculatedTotalWeight, weightUnit, adjustedWeight])

	const handleItemChange = (key, value, index) => {
		const templooseDetails = JSON.parse(JSON.stringify(looseDetails))
		if (key == 'stackable' || key == 'turnable') {
			templooseDetails[index][key] = !value
		} else {
			if (
				key == 'length' ||
				key == 'width' ||
				key == 'height' ||
				key == 'pieces'
			) {
				if (value.length <= 5) {
					templooseDetails[index][key] = value
				}
			} else if (key == 'weight') {
				if (value.length <= 9) {
					templooseDetails[index][key] = value
				}
			} else {
				templooseDetails[index][key] = value
			}
			draftCallback(true)
		}
		setLooseDetails([...templooseDetails])

		if (key == 'weightType') {
			handleWeightInput(templooseDetails)
		}
	}

	const handleWeightInput = (LooseDetails) => {
		const calculatedTotalWeight = [...LooseDetails, {}].reduce((curr, next) => {
			return {
				weight:
					(curr.weightType === DETAILS_APPLY_FOR.PER_ITEM
						? (curr.pieces ? curr.pieces * 1 : 0) *
						(curr.weight ? curr.weight * 1 : 0)
						: curr.weight
							? curr.weight * 1
							: 0) +
					(next.weightType === DETAILS_APPLY_FOR.PER_ITEM
						? (next.pieces ? next.pieces * 1 : 0) *
						(next.weight ? next.weight * 1 : 0)
						: next.weight
							? next.weight * 1
							: 0),
				pieces: 1,
			}
		}).weight
		setCalculatedTotalWeight(calculatedTotalWeight)
		setActualTotalIneItemWeight(calculatedTotalWeight)
	}

	const handleLineItemWeightRemoval = () => {
		let looseDetailsErrors =
			error && error[FORM_FIELDS.LOOSE_ITEMS]
				? error[FORM_FIELDS.LOOSE_ITEMS]
				: []
		looseDetailsErrors = validateLineItemWeights(
			looseDetails,
			looseDetailsErrors
		)
		setError({ ...error, [FORM_FIELDS.LOOSE_ITEMS]: looseDetailsErrors })
	}

	const handleSummaryChange = (LooseDetails) => {
		let quantity = 0
		let totalVolumeinMeters = 0
		let actualVolumeInSelectedUnit = 0
		LooseDetails?.forEach(function (element) {
			if (element.pieces) {
				quantity += element.pieces * 1
			}
			if (element.pieces && element.length && element.width && element.height) {
				actualVolumeInSelectedUnit +=
					parseFloat(element.width * element.length * element.height) *
					parseInt(element.pieces)
			}
			if (element.pieces && element.length && element.width && element.height) {
				if (dimensionUnit == DIMENSION_UNIT.INCHES) {
					totalVolumeinMeters +=
						parseFloat(
							element.width *
							0.0254 *
							(element.length * 0.0254) *
							(element.height * 0.0254)
						) * parseInt(element.pieces)
				}
				if (dimensionUnit == DIMENSION_UNIT.CENTIMETERS) {
					totalVolumeinMeters +=
						parseFloat(
							element.width *
							0.01 *
							(element.length * 0.01) *
							(element.height * 0.01)
						) * parseInt(element.pieces)
				}
			}
		})
		if (totalVolumeinMeters && totalVolumeinMeters < 0.01) {
			totalVolumeinMeters = 0.01
		}
		if (actualVolumeInSelectedUnit && actualVolumeInSelectedUnit < 0.01) {
			actualVolumeInSelectedUnit = 0.01
		}
		setActualVolume(actualVolumeInSelectedUnit)
		setTotalVolumeInMeters(totalVolumeinMeters)
		setTotalPieces(quantity)
	}
	const handleRemoveItem = (index) => {
		const looseDetailsTemp = looseDetails.filter((itemDetail, curr) => {
			return index !== curr
		})
		setLooseDetails(looseDetailsTemp)
		handleWeightInput(looseDetailsTemp)
	}

	const clearError = (key) => {
		const tempError = error ? { ...error } : null
		if (tempError) {
			tempError[key] = null
			setError(tempError)
		}
	}
	const handleOpWeightErr = (key) => {
		const tempError = error ? { ...error } : null
		if (tempError) {
			tempError[key] = validateOperationalWeight(totalWeight, adjustedWeight)
			setError(tempError)
		}
	}
	const clearItemError = (key, index) => {
		const tempError = error ? { ...error } : null
		if (
			tempError &&
			tempError[FORM_FIELDS.LOOSE_ITEMS] &&
			tempError[FORM_FIELDS.LOOSE_ITEMS][index]
		) {
			tempError[FORM_FIELDS.LOOSE_ITEMS][index][key] = null
			setError(tempError)
		}
	}

	const handleClickAdd = (e) => {
		handleAddLineItem(e)
	}
	const isNum = (val) => /^\d+$/.test(val)

	const validateLineItemOnBlur = (type, key) => {
		let looseDetailsErrors =
			error && error[FORM_FIELDS.LOOSE_ITEMS]
				? error[FORM_FIELDS.LOOSE_ITEMS]
				: []
		if (type == 'pieces') {
			const errorMsg =
				looseDetails[key].pieces && isNum(looseDetails[key].pieces)
					? null
					: MESSAGES.INVALID_PIECES
			if (errorMsg) {
				looseDetailsErrors[key] = looseDetailsErrors[key]
					? { ...looseDetailsErrors[key], [LOOSE_FORM_FIELDS.PIECES]: errorMsg }
					: { [LOOSE_FORM_FIELDS.PIECES]: errorMsg }
			}
			setError({ ...error, [FORM_FIELDS.LOOSE_ITEMS]: looseDetailsErrors })
		}
		if (type == 'width') {
			const errorMsg =
				looseDetails[key].width && isNum(looseDetails[key].width)
					? null
					: MESSAGES.INVALID_WIDTH
			if (errorMsg) {
				looseDetailsErrors[key] = looseDetailsErrors[key]
					? { ...looseDetailsErrors[key], [LOOSE_FORM_FIELDS.WIDTH]: errorMsg }
					: { [LOOSE_FORM_FIELDS.WIDTH]: errorMsg }
			}
			setError({ ...error, [FORM_FIELDS.LOOSE_ITEMS]: looseDetailsErrors })
		}
		if (type == 'height') {
			const errorMsg =
				looseDetails[key].height && isNum(looseDetails[key].height)
					? null
					: MESSAGES.INVALID_HEIGHT
			if (errorMsg) {
				looseDetailsErrors[key] = looseDetailsErrors[key]
					? { ...looseDetailsErrors[key], [LOOSE_FORM_FIELDS.HEIGHT]: errorMsg }
					: { [LOOSE_FORM_FIELDS.HEIGHT]: errorMsg }
			}
			setError({ ...error, [FORM_FIELDS.LOOSE_ITEMS]: looseDetailsErrors })
		}
		if (type == 'length') {
			const errorMsg =
				looseDetails[key].length && isNum(looseDetails[key].length)
					? null
					: MESSAGES.INVALID_LENGTH
			if (errorMsg) {
				looseDetailsErrors[key] = looseDetailsErrors[key]
					? { ...looseDetailsErrors[key], [LOOSE_FORM_FIELDS.LENGTH]: errorMsg }
					: { [LOOSE_FORM_FIELDS.LENGTH]: errorMsg }
			}
			setError({ ...error, [FORM_FIELDS.LOOSE_ITEMS]: looseDetailsErrors })
		}
	}
	//============== for bulk upload ===============//
	const isEmpty = looseDetails?.map((item) => {
		if (
			item.pieces === '' &&
			item.length === '' &&
			item.height === '' &&
			item.stackable === true &&
			item.turnable === true &&
			item.weight === '' &&
			item.weightType === 'PER_ITEM' &&
			item.width === ''
		) {
			return true
		} else return false
	})

	const checkIsEmpty = () => {
		let isLooseEmpty = true
		looseDetails?.filter((item) => {
			if (
				item.pieces !== '' ||
				item.length !== '' ||
				item.height !== '' ||
				item.weight != '' ||
				item.width != '' ||
				item.weightType !== 'PER_ITEM' 
			) {
				isLooseEmpty = false
			}
		})
		return isLooseEmpty;
	}

	const onReadExcel = (val, weightUnit, dimensionUnit) => {

		//============== for bulk dimension & weight unit ===============//
		if (dimensionUnit === 'cm') {
			setDimensionUnit('C')
		} else if (dimensionUnit === 'inch') {
			setDimensionUnit('I')
		} else return

		if (weightUnit === 'kg') {
			setWeightUnit('K')
		} else if (weightUnit === 'lb') {
			setWeightUnit('L')
		} else if (weightUnit === 't') {
			setWeightUnit('T')
		} else return
		//=================================================//		
		let tempLooseDetails = [...looseDetails]
		if (checkIsEmpty()) {
			tempLooseDetails = val
			setLooseDetails(val)
		} else {
			tempLooseDetails = tempLooseDetails.concat(val)
			setLooseDetails(tempLooseDetails)
		}
		let looseDetailsErrors = validateLooseDetails(tempLooseDetails)
		const hasError = looseDetailsErrors.some((item) => (item ? true : false))
		error[FORM_FIELDS.LOOSE_ITEMS] = hasError ? looseDetailsErrors : null
	}
	//==============================================//

	const toggleAllStackable = useCallback(
		(event) => {
			setLooseDetails(
				looseDetails.map((item) => ({ ...item, stackable: !isAllStackable }))
			)
			setDisableButton && setDisableButton(false)

		},
		[looseDetails, setLooseDetails, isAllStackable]
	)

	const toggleAllTiltable = useCallback(
		(event) => {
			setLooseDetails(
				looseDetails.map((item) => ({ ...item, turnable: !isAllTiltable }))
			)
			setDisableButton && setDisableButton(false)

		},
		[looseDetails, setLooseDetails, isAllTiltable]
	)

	useEffect(() => {
		var isStackable = looseDetails.every((stack) => stack.stackable)
		var isTiltable = looseDetails.every((tilt) => tilt.turnable)
		setAllStackable(isStackable)
		setAllTiltable(isTiltable)
	}, [looseDetails, setAllStackable, setAllTiltable])

	/*=================== OPERATIONAL WEIGHT=========================*/
	const handleOperationalWeight = () => {
		setShowOpweight(true)
	}
	return (
		<>
			<div className='loose-form'>
				<div className='row m-b-0'>
					<div className={`form-group col-6 col-sm-3 col-md-auto col-lg-auto`}>
						<label>{t('COMMON.TOTAL_WT')}</label>
						<div
							className={`search-panel__double_element weight-div ${error && error[FORM_FIELDS.TOTAL_WEIGHT] ? 'red-border' : ''
								}`}
						>
							<input
								type='text'
								className='form-control borderRadius-R0'
								value={totalWeight === 0 ? null : totalWeight}
								//value={totalWeight}
								onKeyDown={(evt) => allowNumberOnly(evt)}
								onChange={(e) => {
									if (
										e.target.value.length <= 9 &&
										//e.target.value.match(/^(\d*\.{0,1}\d{0,2}$)/)
										(!e.target.value.split('.')[1] ||
											!e.target.value.split('.')[1].length ||
											e.target.value.split('.')[1].length < 3) &&
										e.target.value.split('.').length <= 2
									) {
										const value = e.target.value.replace(/[^0-9.]/g, '')
										setDisableButton && setDisableButton(false)
										setTotalWeight(value)
										draftCallback()
									}
								}}
								onFocus={(e) => clearError(FORM_FIELDS.TOTAL_WEIGHT)}
								onBlur={(e) => {
									if (!Number(totalWeight)) {
										setTotalWeight(null)
									}
									handleSummaryChange(looseDetails)
									error[FORM_FIELDS.TOTAL_WEIGHT] = validateTotalWeight(totalWeight, calculatedTotalWeight)
								}}
							/>
							<div className='form-group borderRadius-L0 border-left0 dropdown_div m-b-0'>
								<Dropdown
									className='form-control cust-dropdown'
									value={weightUnit}
									options={WEIGHT_UNIT_SELECT_ITEMS}
									onChange={(e) => {
										setDisableButton && setDisableButton(false)
										setWeightUnit(e.target.value)
										draftCallback(true)
									}}
								/>
							</div>
						</div>
						<span className='search-panel__errormsg'>
							{error && error[FORM_FIELDS.TOTAL_WEIGHT]
								? t(error[FORM_FIELDS.TOTAL_WEIGHT], { ns: 'errors' })
								: ''}
						</span>
					</div>

					{!showOpWeight && !isHideReprocessComponents && adjustedWeight == null &&
						<div
							className={`form-group col-12 col-md-6 ${showOpWeight ? 'd-none' : 'col-6 col-sm-6 col-md-6 d-flex'
								}`}>
							<Button
								className='p-button-link add-overflow-link pb-0 pt-0 align-items-end'
								label={t('BOOKING.OPERATIONAL_WEIGHT_OPT')}
								type='button'

								onClick={(e) => handleOperationalWeight(e)}
							/>
							{/* <div className='d-flex' style={{ paddingTop: '0.8rem',height:'30px' }}>
								<img className='pr-3' src={infoIcon} />
						</div> */}
						</div>}


					{(showOpWeight || adjustedWeight) && (
						<div className='form-group col-12 col-md-9 col-lg-9 mt-0'>
							<div className='row mb-0'>
								<div className={`col-6 col-md-auto col-lg-auto`}>
									<label >{t('BOOKING.OPERATIONAL_WEIGHT')}</label>
									<div
										className={`search-panel__double_element weight-div ${error && error[FORM_FIELDS.OPERATIONAL_WEIGHT]
											? 'red-border'
											: ''
											}`}
									>
										<input
											type='text'
											className='form-control borderRadius-R0'
											value={adjustedWeight}
											onKeyDown={(evt) => allowNumberOnly(evt)}
											onChange={(e) => {
												if (
													e.target.value.length <= 9 &&
													//e.target.value.match(/^(\d*\.{0,1}\d{0,2}$)/)
													(!e.target.value.split('.')[1] ||
														!e.target.value.split('.')[1].length ||
														e.target.value.split('.')[1].length < 3) &&
													e.target.value.split('.').length <= 2
												) {
													const value = e.target.value.replace(/[^0-9.]/g, '')
													setAdjustedWeight(value)
													draftCallback()
												}
											}}
											onFocus={(e) => clearError(FORM_FIELDS.OPERATIONAL_WEIGHT)}
											onBlur={(e) => {
												if (!Number(adjustedWeight)) {
													setAdjustedWeight(null)
												}
												//handleSummaryChange(looseDetails)
												handleOpWeightErr(FORM_FIELDS.OPERATIONAL_WEIGHT)
											}}
										/>
										<div className='form-group borderRadius-L0 border-left0 operational_weight_dropdown m-b-0'>
											<Dropdown
												className='form-control cust-dropdown'
												value={weightUnit}
												options={WEIGHT_UNIT_SELECT_ITEMS}
												onChange={(e) => {
													setDisableButton && setDisableButton(false)
													setWeightUnit(e.target.value)
													draftCallback(true)
												}}
											/>
										</div>
								<div className='col-12 col-md-8 col-lg-8'>
									<div className='ops-weight d-flex'>
										<img className='pr-3' src={infoIcon} />
										<div className='' id='pivot-styling'>
											{t('COMMON.OP_WEIGHT_HINT')}
										</div>
									</div>
								</div>
									</div>
									<span className='search-panel__errormsg'>
										{error && error[FORM_FIELDS.OPERATIONAL_WEIGHT]
											? t(error[FORM_FIELDS.OPERATIONAL_WEIGHT], { ns: 'errors' })
											: ''}
									</span>
								</div>
								
							</div>
						</div>

					)}

				</div>
				<div className='form-row mb-0'>
					<div className='form-group col-12 col-md-4 m-t-10'>
						<label>{t('COMMON.ALL_PCS_ARE')}</label>
						<div className='mt-2 mb-3 search-panel__type grey-div pl-0 pr-0'>
							<div className='l-checkbox'>
								<input
									id={`stackable`}
									type='checkbox'
									checked={isAllStackable}
									onChange={toggleAllStackable}

								/>
								<label className='mb-0' htmlFor={`stackable`}>
									{t('COMMON.STACKABLE')}
								</label>
							</div>
							<div className='l-checkbox'>
								<input
									id={`turnable`}
									type='checkbox'
									checked={isAllTiltable}
									onChange={toggleAllTiltable}
								/>
								<label className='mb-0' htmlFor={`turnable`}>
									{t('COMMON.TILTABLE')}
								</label>
							</div>
						</div>
					</div>
				</div>
				{looseDetails &&
					looseDetails.length &&
					looseDetails.map((item, key) => {
						const piecesError =
							error &&
								error[FORM_FIELDS.LOOSE_ITEMS] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.PIECES]
								? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.PIECES]
								: null
						const lengthError =
							error &&
								error[FORM_FIELDS.LOOSE_ITEMS] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.LENGTH]
								? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.LENGTH]
								: null
						const widthError =
							error &&
								error[FORM_FIELDS.LOOSE_ITEMS] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.WIDTH]
								? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.WIDTH]
								: null
						const heightError =
							error &&
								error[FORM_FIELDS.LOOSE_ITEMS] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.HEIGHT]
								? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.HEIGHT]
								: null
						const weightError =
							error &&
								error[FORM_FIELDS.LOOSE_ITEMS] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key] &&
								error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.WEIGHT]
								? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.WEIGHT]
								: null
						return (
							<div
								className={`item-group search-panel__data ${looseDetails.length - 1 === key ? 'last-item' : ''
									}`}
								key={key}
							>
								<div className='d-flex flex-wrap'>
									<div className='form-row mb-1'>
										<div
											className={`form-group pieces-div col-12 col-lg-auto ${piecesError ? 'red-border' : ''
												}`}
										>
											<div className='row mb-0'>
												<div
													className="form-group mb-0 col-6 col-lg-12">
													<label>{t('COMMON.PCS')}</label>
													<input
														type='text'
														className='form-control'
														value={item.pieces === 0 ? null : item.pieces}
														//value={item.pieces}
														id={`quantity-${key}`}

														onChange={(e) => {
															const value = e.target.value.replace(/[^\d]/, '');
															setDisableButton && setDisableButton(false)

															handleItemChange('pieces', value, key)
														}

														}
														onFocus={(e) =>
															clearItemError(LOOSE_FORM_FIELDS.PIECES, key)
														}
														onBlur={(e) => {
															if (!Number(item.pieces)) {
																handleItemChange('pieces', '', key)
															}
															validateLineItemOnBlur('pieces', key)
															handleWeightInput(looseDetails)
														}}
													/>
													<span className='search-panel__errormsg'>
														{piecesError ? t(piecesError, { ns: 'errors' }) : ''}
													</span>
												</div>
											</div>
										</div>
										<div
											className={`form-group col-6 col-lg-1 ${lengthError ? 'red-border' : ''
												}`}
										>
											<label>{t('COMMON.LN')}</label>
											<input
												type='text'
												className='form-control'
												value={item.length === 0 ? null : item.length}
												//value={item.length}
												id={`length-${key}`}
												onChange={(e) => {
													const value = e.target.value.replace(/[^\d]/, '');
													setDisableButton && setDisableButton(false)

													handleItemChange('length', value, key)
												}
												}
												onFocus={(e) =>
													clearItemError(LOOSE_FORM_FIELDS.LENGTH, key)
												}
												onBlur={(e) => {
													if (!Number(item['length'])) {
														handleItemChange('length', '', key)
													}
													validateLineItemOnBlur('length', key)
													handleSummaryChange(looseDetails)
												}}
											/>
											<span className='search-panel__errormsg'>
												{lengthError ? t(lengthError, { ns: 'errors' }) : ''}
											</span>
										</div>

										<div
											className={`form-group col-6 col-lg-1 ${widthError ? 'red-border' : ''
												}`}
										>
											<label>{t('COMMON.WD')}</label>
											<input
												type='text'
												className='form-control'
												id={`width-${key}`}
												value={item.width === 0 ? null : item.width}
												//value={item.width}
												onChange={(e) => {
													const value = e.target.value.replace(/[^\d]/, '');
													setDisableButton && setDisableButton(false)

													handleItemChange('width', value, key)
												}
												}
												onFocus={(e) =>
													clearItemError(LOOSE_FORM_FIELDS.WIDTH, key)
												}
												onBlur={(e) => {
													if (!Number(item.width)) {
														handleItemChange('width', '', key)
													}
													validateLineItemOnBlur('width', key)
													handleSummaryChange(looseDetails)
												}}
											/>
											<span className='search-panel__errormsg'>
												{widthError ? t(widthError, { ns: 'errors' }) : ''}
											</span>
										</div>
										<div
											className={`form-group col-6 col-lg-1 ${heightError ? 'red-border' : ''
												}`}
										>
											<label>{t('COMMON.HT')}</label>
											<input
												type='text'
												className='form-control'
												id={`height-${key}`}
												value={item.height === 0 ? null : item.height}
												//value={item.height}
												onChange={(e) => {
													const value = e.target.value.replace(/[^\d]/, '');
													setDisableButton && setDisableButton(false)

													handleItemChange('height', value, key)
												}
												}
												onFocus={(e) =>
													clearItemError(LOOSE_FORM_FIELDS.HEIGHT, key)
												}
												onBlur={(e) => {
													if (!Number(item.height)) {
														handleItemChange('height', '', key)
													}
													validateLineItemOnBlur('height', key)
													handleSummaryChange(looseDetails)
												}}
											/>
											<span className='search-panel__errormsg'>
												{heightError ? t(heightError, { ns: 'errors' }) : ''}
											</span>
										</div>
										<div className='form-group col-6 col-lg-1 mr-0'>
											<label>&nbsp;</label>
											<Dropdown
												value={dimensionUnit}
												options={DIMENSION_UNIT_SELECT_ITEMS}
												onChange={(e) => {
													setDimensionUnit(e.target.value)
													setDisableButton && setDisableButton(false)

													handleItemChange('dimensionUnit', e.target.value, key)
													draftCallback(true)
												}}
												className={'form-control'}
												disabled={key ? true : false}
												dataKey={item.dimensionUnit}
											/>
										</div>
										{!isHideReprocessComponents &&
											<div
												className={`form-group col-md-4 col-6 col-lg-auto weight-div ${weightError ? 'red-border' : ''
													}`}
											>
												<label>{t('COMMON.WT')} </label>
												<div className='search-panel__double_element'>
													<input
														type='text'
														className='form-control borderRadius-R0'
														value={item.weight}

														onChange={(e) => {
															const value = e.target.value.replace(/[^0-9.]/g, '');
															if (value.length <= 9 &&
																//e.target.value.match(/^(\d*\.{0,1}\d{0,2}$)/)
																(
																	!value.split('.')[1] ||
																	!value.split('.')[1].length ||
																	value.split('.')[1].length < 3
																) &&
																(value.split('.').length <= 2)
															) {
																setDisableButton && setDisableButton(false)

																handleItemChange('weight', value, key)
															}
															handleSummaryChange(looseDetails)
														}
														}
														onFocus={(e) =>
															clearItemError(LOOSE_FORM_FIELDS.WEIGHT, key)
														}
														onBlur={(e) => {
															handleWeightInput(looseDetails)
															if (
																(!e.target.value || !e.target.value.length) &&
																!looseDetails.some((detail) => detail.weight)
															) {
																handleLineItemWeightRemoval()
															}
														}}
													/>
													<div className='m-r-10 borderRadius-L0 border-left0 dropdown_div'>
														<Dropdown
															className='form-control cust-dropdown'
															value={weightUnit}
															options={WEIGHT_UNIT_SELECT_ITEMS}
															onChange={(e) => {
																setDisableButton && setDisableButton(false)
																setWeightUnit(e.target.value)
																handleItemChange('weightUnit', e.target.value, key)
															}}
															disabled={key ? true : false}
														/>
													</div>
												</div>
												<span className='search-panel__errormsg'>
													{weightError ? t(weightError, { ns: 'errors' }) : ''}
												</span>
											</div>
										}
										{!isHideReprocessComponents &&
											<div className='form-group col-12 col-lg-1 no-wrap-type'>
												<label>{t('COMMON.WT_TYPE')}</label>
												<div className='d-flex d-lg-block border-0 p-l-0 p-t-0 mt-2'>
													<div className='flex-fill l-radio'>
														<input
															type='radio'
															id={`perItem-${key}`}
															name='radio'
															value={DETAILS_APPLY_FOR.PER_ITEM}
															checked={
																item.weightType === DETAILS_APPLY_FOR.PER_ITEM
															}
															onChange={(e) => {
																setDisableButton && setDisableButton(false)
																handleItemChange('weightType', e.target.value, key)
															}}
														/>
														<label
															id="per-item-styling"
															className={`mb-0 ${item.weightType === DETAILS_APPLY_FOR.PER_ITEM
																? 'checked'
																: ''
																}`}
															htmlFor={`perItem-${key}`}
														>
															{t('COMMON.PER_ITEM')}
														</label>
													</div>
													<div className='flex-fill l-radio'>
														<input
															type='radio'
															id={`total-${key}`}
															name='radio'
															value={DETAILS_APPLY_FOR.TOTAL}
															checked={item.weightType === DETAILS_APPLY_FOR.TOTAL}
															onChange={(e) => {
																setDisableButton && setDisableButton(false)
																handleItemChange('weightType', e.target.value, key)
																handleWeightInput(looseDetails)
																handleSummaryChange(looseDetails)
															}}
														/>
														<label
															id="per-item-styling"
															className={`mb-0 ${item.weightType === DETAILS_APPLY_FOR.TOTAL
																? 'checked'
																: ''
																}`}
															htmlFor={`total-${key}`}
														>
															{t('COMMON.TOTAL')}
														</label>
													</div>
												</div>
											</div>
										}
										<div className='form-group col-12 col-lg-2 custom-pl-4 mb-0'>
											<label>{t('COMMON.PCKG_TYPE')}</label>
											<div className='d-flex d-lg-block border-0 p-l-0 mt-2'>
												<div className='flex-fill l-checkbox'>
													<input
														id={`stackable-${key}`}
														type='checkbox'
														disabled={isAllStackable}
														checked={!item.stackable}
														onChange={(e) =>

															handleItemChange('stackable', e.target.checked, key)
														}
													/>
													<label className='mb-0' htmlFor={`stackable-${key}`}>
														{t('COMMON.NON_STACKABLE')}
													</label>
												</div>
												<div className='flex-fill l-checkbox'>
													<input
														id={`turnable-${key}`}
														type='checkbox'
														disabled={isAllTiltable}
														checked={!item.turnable}
														onChange={(e) =>
															handleItemChange('turnable', e.target.checked, key)
														}
													/>
													<label className='mb-0' htmlFor={`turnable-${key}`}>
														{t('COMMON.NON_TILTABLE')}
													</label>
												</div>
											</div>
										</div>
										{looseDetails.length > 1 ? (
											<div class='delete-section col-12 col-lg-auto pl-3'>
												<img
													onClick={(e) => handleRemoveItem(key)}
													src={trashIcon}
													alt='Trash'
													title='Trash'
												/>
												<span>{t('COMMON.DELETE')}</span>
											</div>
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
						)
					})}
				{!isHideReprocessComponents &&
					<div className='row mb-0'>
						<div
							className='form-group col-12 col-lg-12 m-b-0 d-lg-flex flex-lg-wrap mt-2'
						>
							<div className='form-row mb-0'>
								<div className='col-12 col-lg-7'>
									<Button
										label={t('COMMON.ADD_ANOTHER')}
										icon='pi pi-plus'
										className='d-block custom-xs button__another'
										type='button'
										onClick={(e) => handleClickAdd(e)}
									/>
								</div>

								<div className='col-lg-5 col-12'>
									<ExcelReader
										onRead={onReadExcel} />
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		</>
	)
}

export default LooseItemForm
