import React, { useEffect, useState } from 'react'
import {
	validateAlphaIfPresent,
	validateAlphaNumericIfPresent,
	validateAddressIfPresent,
	validateRequired,
} from '../../CaptureAWB/captureAwbUtils'
import {
	hasError,
	removeError,
	validateEmailIfPresent,
	validateNameRequired,
	validatePhoneNumberIfPresent,
	validatePostalCodeIfPresent,
	validateParticipants,
} from '../CaptureHawbValidations'
import './index.scss'
import CountryAutocomplete from '../../CountryAutocomplete'
import { AUTOCOMPLETE_TYPE_COUNTRY } from '../../../constants'
import { useTranslation } from 'react-i18next'
const ParticipantDetails = ({
	isExpanded,
	hawbResponseDetails,
	shipperDetails,
	setShipperDetails,
	consigneeDetails,
	setConsigneeDetails,
	awbResponseDetails,
	initialStateConsigneeDetails,
	initialStateShipperDetails,
	error,
	setError,
}) => {
	//for multilingual
	const { t } = useTranslation(['labels', 'errors'])

	const [awbShipperDetails, setAwbShipperDetails] = useState(null)
	const [awbConsigneeDetails, setAwbConsigneeDetails] = useState(null)
	const [existingHawbShipperDetails, setExistingHawbShipperDetails] =
		useState(null)
	const [existingHawbConsigneeDetails, setExistingConsigneerDetails] =
		useState(null)
	const [selectedShipperCountry, setSelectedShipperCountry] = useState(null)
	const [selectedCosigneeCountry, setSelectedCosigneeCountry] = useState(null)

	useEffect(() => {
		if (awbResponseDetails) {
			setAwbShipperDetails(awbResponseDetails.awbShipperDetails)
			setAwbConsigneeDetails(awbResponseDetails.awbConsigneeDetails)
		}
		if (hawbResponseDetails && hawbResponseDetails.shipperDetails) {
			setShipperDetails(hawbResponseDetails.shipperDetails)
			setExistingHawbShipperDetails(hawbResponseDetails.shipperDetails)
			setSelectedShipperCountry(
				hawbResponseDetails.shipperDetails.shipperCountry
			)
		}
		if (hawbResponseDetails && hawbResponseDetails.consigneeDetails) {
			setConsigneeDetails(hawbResponseDetails.consigneeDetails)
			setExistingConsigneerDetails(hawbResponseDetails.consigneeDetails)
			setSelectedCosigneeCountry(
				hawbResponseDetails.consigneeDetails.consigneeCountry
			)
		}
	}, [hawbResponseDetails, awbResponseDetails])

	useEffect(() => {
		setShipperDetails({
			...shipperDetails,
			shipperCountry: selectedShipperCountry,
		})
	}, [selectedShipperCountry])

	useEffect(() => {
		setConsigneeDetails({
			...consigneeDetails,
			consigneeCountry: selectedCosigneeCountry,
		})
	}, [selectedCosigneeCountry])

	const handleChangeShipperDetail = (evnt) => {
		if (evnt.target.checked && awbShipperDetails) {
			setShipperDetails({
				...shipperDetails,
				shipperName: awbShipperDetails.shipperName,
				shipperAddress1: awbShipperDetails.shipperAddress1,
				shipperCity: awbShipperDetails.shipperCity,
				shipperState: awbShipperDetails.shipperState,
				shipperCountry: awbShipperDetails.shipperCountry,
				shipperPostalCode: awbShipperDetails.shipperPostalCode,
				shipperAccountNumber: awbShipperDetails.shipperAccountNumber,
				shipperPhone: awbShipperDetails.shipperPhone,
				shipperMail: awbShipperDetails.shipperMail,
			})
			checkShipperValidations()
		} else if (!evnt.target.checked && existingHawbShipperDetails !== null) {
			setShipperDetails({ ...existingHawbShipperDetails })
			checkShipperValidations()
		} else {
			setShipperDetails({ ...initialStateShipperDetails })
		}
	}

	const checkShipperValidations = () => {
		setError((prevState) => ({ ...prevState, 'shipperName': '' }))
		setError((prevState) => ({ ...prevState, 'shipperAddress': '' }))
		setError((prevState) => ({ ...prevState, 'shipperCity': '' }))
		setError((prevState) => ({ ...prevState, 'shipperCountry': '' }))
		validateParticipants(awbShipperDetails, awbConsigneeDetails, setError)
	}

	const handleChangeConsigneeDetail = (evnt) => {
		if (evnt.target.checked && consigneeDetails) {
			setConsigneeDetails({
				...consigneeDetails,
				consigneeName: awbConsigneeDetails.consigneeName,
				consigneeAddress1: awbConsigneeDetails.consigneeAddress1,
				consigneeCity: awbConsigneeDetails.consigneeCity,
				consigneeState: awbConsigneeDetails.consigneeState,
				consigneeCountry: awbConsigneeDetails.consigneeCountry,
				consigneePostalCode: awbConsigneeDetails.consigneePostalCode,
				consigneeAccountNumber: awbConsigneeDetails.consigneeAccountNumber,
				consigneePhone: awbConsigneeDetails.consigneePhone,
				consigneeMail: awbConsigneeDetails.consigneeMail,
			})
			checkConsigneeValidations()
		} else if (!evnt.target.checked && existingHawbConsigneeDetails !== null) {
			setConsigneeDetails({ ...existingHawbConsigneeDetails })
			checkConsigneeValidations()
		} else {
			setConsigneeDetails({ ...initialStateConsigneeDetails })
		}
	}

	const checkConsigneeValidations = () => {
		setError((prevState) => ({ ...prevState, 'consigneeName': '' }))
		setError((prevState) => ({ ...prevState, 'consigneeAddress': '' }))
		setError((prevState) => ({ ...prevState, 'consigneeCity': '' }))
		setError((prevState) => ({ ...prevState, 'consigneeCountry': '' }))
		validateParticipants(awbShipperDetails, awbConsigneeDetails, setError)
	}


	return (
		<div className='mt-5 mb-3 participant-details-wrapper'>
			<div className='form-row '>
				<div className='col-12'>
					<h4 className='page-sub-title mt-0'>{t('COMMON.PAR_DTLS')}</h4>
				</div>
			</div>
			<div className='form-row mt-2'>
				<div className='col-12 col-lg-4'>
					<p className='participant-sub-title'>{t('COMMON.SHP_DTLS')}</p>
					<div className='form-row m-t-25'>
						<div className='col-12'>
							<div
								className='form-item form-item--checkbox'
								data-module='molecules/form-item/FormItem'
							>
								<input
									id={`${
										isExpanded
											? 'expanded-shipper-same-as-Awb'
											: 'addAnother-shipper-same-as-Awb'
									}  `}
									// checked={privileges.bookingPrivilege}
									// value={privileges.bookingPrivilege}
									type='checkbox'
									name={`${
										isExpanded
											? 'expanded-shipper-same-as-Awb'
											: 'addAnother-shipper-same-as-Awb'
									}  `}
									className='form-item__field'
									onChange={(evnt) => handleChangeShipperDetail(evnt)}
								/>
								<label
									htmlFor={`${
										isExpanded
											? 'expanded-shipper-same-as-Awb'
											: 'addAnother-shipper-same-as-Awb'
									}  `}
									className='form-item__label'
								>
									{t('CAPTURE_HAWB.S_A_AWB')}
								</label>
							</div>
						</div>
					</div>
					<div className='form-row mt-4 mb-2 mb-lg-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperName'>
								{' '}
								{t('COMMON.NAME')}
							</label>
							<input
								id='shipperName'
								name='shipperName'
								type='text'
								className='form-item__field'
								placeholder='Name'
								maxLength='70'
								value={shipperDetails.shipperName ? shipperDetails.shipperName.toUpperCase(): ''}
								onChange={(e) => {
									setShipperDetails({
										...shipperDetails,
										shipperName: e.target.value,
									})
								}}
								onBlur={(e) => {
									validateNameRequired(
										e.target.value,
										setError,
										'shipperName',
										'Name'
									)
								}}
								aria-invalid={hasError('shipperName', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperName']
									? t(error['shipperName'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperAddress'>
								{t('COMMON.ADDRS')}
							</label>
							<input
								id='shipperAddress'
								name='shipperAddress'
								type='text'
								className='form-item__field'
								placeholder='Address'
								maxLength='70'
								value={shipperDetails.shipperAddress1 ? shipperDetails.shipperAddress1.toUpperCase() : ''}
								onChange={(e) => {
									setShipperDetails({
										...shipperDetails,
										shipperAddress1: e.target.value,
									})
								}}
								onBlur={(e) => {
									validateRequired(
										e.target.value,
										setError,
										'shipperAddress',
										t('COMMON.ADDRS')
									)
								}}
								aria-invalid={hasError('shipperAddress', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperAddress']
									? t(error['shipperAddress'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperCity'>
								{t('COMMON.CITY')}
							</label>
							<input
								id='shipperCity'
								name='shipperCity'
								type='text'
								className='form-item__field'
								placeholder='City'
								maxLength='70'
								value={shipperDetails.shipperCity ? shipperDetails.shipperCity.toUpperCase():''}
								onChange={(e) => {
									setShipperDetails({
										...shipperDetails,
										shipperCity: e.target.value,
									})
								}}
								onBlur={(e) => {
									validateRequired(
										e.target.value,
										setError,
										'shipperCity',
										t('COMMON.CITY')
									)
								}}
								aria-invalid={hasError('shipperCity', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperCity']
									? t(error['shipperCity'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperState'>
								{t('COMMON.STATE')}
							</label>
							<input
								id='shipperState'
								name='shipperState'
								type='text'
								className='form-item__field'
								placeholder='State'
								maxLength='70'
								value={shipperDetails.shipperState ? shipperDetails.shipperState.toUpperCase(): ''}
								onChange={(e) => {
									setShipperDetails({
										...shipperDetails,
										shipperState: e.target.value,
									})
								}}
								onBlur={(e) => {
									validateAlphaIfPresent(
										e.target.value,
										setError,
										'shipperState'
									)
								}}
								aria-invalid={hasError('shipperState', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperState']
									? t(error['shipperState'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>

					<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
						<div
							className={`col-12 form-item form-item--text ${
								hasError('shipperCountry', error) ? 'red-border' : ''
							}`}
						>
							<label className='form-item__label' htmlFor='shipperCountry'>
								{t('COMMON.CNTRY')}
							</label>
							<CountryAutocomplete
								autoCompleteType={AUTOCOMPLETE_TYPE_COUNTRY.SHIPPER}
								selectedShipperCountry={shipperDetails.shipperCountry}
								selectedCosigneeCountry={consigneeDetails.consigneeCountry}
								setSelectedShipperCountry={setSelectedShipperCountry}
								isMandatory={true}
								setError={setError}
							/>
							<span className='form-item__error'>
								{error && error['shipperCountry']
									? t(error['shipperCountry'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperZipCode'>
								{t('COMMON.ZIP_CODE')}
							</label>
							<input
								id='shipperZipCode'
								name='shipperZipCode'
								type='text'
								className='form-item__field'
								placeholder='Zip code'
								maxLength='10'
								value={shipperDetails.shipperPostalCode ? shipperDetails.shipperPostalCode.toUpperCase() :''}
								onChange={(e) => {
									setShipperDetails({
										...shipperDetails,
										shipperPostalCode: e.target.value,
									})
								}}
								onBlur={(e) => {
									validatePostalCodeIfPresent(
										e.target.value,
										setError,
										'shipperZipCode'
									)
								}}
								aria-invalid={hasError('shipperZipCode', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperZipCode']
									? t(error['shipperZipCode'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label
								className='form-item__label'
								htmlFor='shipperAccountNumber'
							>
								{t('COMMON.ACCNT_NO')}
							</label>
							<input
								id='shipperAccountNumber'
								name='shipperAccountNumber'
								type='text'
								className='form-item__field'
								placeholder='Account number'
								maxLength='14'
								value={shipperDetails.shipperAccountNumber ? shipperDetails.shipperAccountNumber.toUpperCase(): ''}
								onChange={(e) => {
									setShipperDetails({
										...shipperDetails,
										shipperAccountNumber: e.target.value,
									})
								}}
								onBlur={(e) => {
									validateAlphaNumericIfPresent(
										e.target.value,
										setError,
										'shipperAccountNumber'
									)
								}}
								aria-invalid={hasError('shipperAccountNumber', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperAccountNumber']
									? t(error['shipperAccountNumber'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>

					<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperTelephone'>
								{t('COMMON.TEL_HEAD')}
							</label>
							<input
								id='shipperTelephone'
								name='shipperTelephone'
								type='text'
								className='form-item__field'
								placeholder='Telephone'
								maxLength='35'
								value={shipperDetails.shipperPhone ? shipperDetails.shipperPhone.toUpperCase(): ''}
								onChange={(e) => {
									setShipperDetails({
										...shipperDetails,
										shipperPhone: e.target.value,
									})
								}}
								onBlur={(e) => {
									validatePhoneNumberIfPresent(
										e.target.value,
										setError,
										'shipperTelephone'
									)
								}}
								aria-invalid={hasError('shipperTelephone', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperTelephone']
									? t(error['shipperTelephone'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>

					<div className='form-row mt-lg-4 mb-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperEmail'>
								{t('COMMON.EMAIL_HEAD')}
							</label>
							<input
								id='shipperEmail'
								name='shipperEmail'
								type='text'
								className='form-item__field'
								placeholder='Email address'
								maxLength='70'
								value={shipperDetails.shipperMail ? shipperDetails.shipperMail.toUpperCase():''}
								onChange={(e) => {
									setShipperDetails({
										...shipperDetails,
										shipperMail: e.target.value,
									})
								}}
								onBlur={(e) => {
									validateEmailIfPresent(
										e.target.value,
										setError,
										'shipperEmail'
									)
								}}
								aria-invalid={hasError('shipperEmail', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperEmail']
									? t(error['shipperEmail'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
				</div>
				<div className='col-12 col-lg-1'></div>
				<div className='col-12 col-lg-4'>
					<p className=' participant-sub-title'>{t('COMMON.CON_DTLS')}</p>
					<div className='form-row m-t-25'>
						<div className='col-12'>
							<div
								className='form-item form-item--checkbox'
								data-module='molecules/form-item/FormItem'
							>
								<input
									id={`${
										isExpanded
											? 'expanded-consignee-same-as-Awb'
											: 'addAnother-consignee-same-as-Awb'
									}  `}
									type='checkbox'
									name={`${
										isExpanded
											? 'expanded-consignee-same-as-Awb'
											: 'addAnother-consignee-same-as-Awb'
									}  `}
									className='form-item__field'
									onChange={(evnt) => handleChangeConsigneeDetail(evnt)}
								/>
								<label
									htmlFor={`${
										isExpanded
											? 'expanded-consignee-same-as-Awb'
											: 'addAnother-consignee-same-as-Awb'
									}  `}
									className='form-item__label'
								>
									{t('CAPTURE_HAWB.S_A_AWB')}
								</label>
							</div>
						</div>
					</div>
					<div className='form-row mt-4 mb-2 mb-lg-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='consigneeName'>
								{t('COMMON.NAME')}
							</label>
							<input
								id='consigneeName'
								name='consigneeName'
								type='text'
								className='form-item__field'
								placeholder='Name'
								maxLength='70'
								value={consigneeDetails.consigneeName ? consigneeDetails.consigneeName.toUpperCase() : ''}
								onChange={(e) => {
									setConsigneeDetails({
										...consigneeDetails,
										consigneeName: e.target.value,
									})
								}}
								onBlur={(e) => {
									validateNameRequired(
										e.target.value,
										setError,
										'consigneeName',
										'Name'
									)
								}}
								aria-invalid={hasError('consigneeName', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeName']
									? t(error['consigneeName'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='consigneeAddress'>
								{t('COMMON.ADDRS')}
							</label>
							<input
								id='consigneeAddress'
								name='consigneeAddress'
								type='text'
								className='form-item__field'
								placeholder='Address'
								maxLength='70'
								value={consigneeDetails.consigneeAddress1 ? consigneeDetails.consigneeAddress1.toUpperCase(): ''}
								onChange={(e) => {
									setConsigneeDetails({
										...consigneeDetails,
										consigneeAddress1: e.target.value,
									})
								}}
								onBlur={(e) => {
									validateRequired(
										e.target.value,
										setError,
										'consigneeAddress',
										t('COMMON.ADDRS')
									)
								}}
								aria-invalid={hasError('consigneeAddress', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeAddress']
									? t(error['consigneeAddress'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='consigneeCity'>
								{t('COMMON.CITY')}
							</label>
							<input
								id='consigneeCity'
								name='consigneeCity'
								type='text'
								className='form-item__field'
								placeholder='City'
								maxLength='70'
								value={consigneeDetails.consigneeCity ? consigneeDetails.consigneeCity.toUpperCase(): ''}
								onChange={(e) => {
									setConsigneeDetails({
										...consigneeDetails,
										consigneeCity: e.target.value,
									})
								}}
								onBlur={(e) => {
									validateRequired(
										e.target.value,
										setError,
										'consigneeCity',
										t('COMMON.CITY')
									)
								}}
								aria-invalid={hasError('consigneeCity', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeCity']
									? t(error['consigneeCity'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='consigneeState'>
								{t('COMMON.STATE')}
							</label>
							<input
								id='consigneeState'
								name='consigneeState'
								type='text'
								className='form-item__field'
								placeholder='State'
								maxLength='70'
								value={consigneeDetails.consigneeState ? consigneeDetails.consigneeState.toUpperCase() : ''}
								onChange={(e) => {
									setConsigneeDetails({
										...consigneeDetails,
										consigneeState: e.target.value,
									})
								}}
								onBlur={(e) => {
									validateAlphaIfPresent(
										e.target.value,
										setError,
										'consigneeState'
									)
								}}
								aria-invalid={hasError('consigneeState', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeState']
									? t(error['consigneeState'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>

					<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
						<div
							className={`col-12 form-item form-item--text ${
								hasError('consigneeCountry', error) ? 'red-border' : ''
							}`}
						>
							<label className='form-item__label' htmlFor='consigneeCountry'>
								{t('COMMON.CNTRY')}
							</label>
							<CountryAutocomplete
								autoCompleteType={AUTOCOMPLETE_TYPE_COUNTRY.CONSIGNEE}
								selectedShipperCountry={shipperDetails.shipperCountry}
								selectedCosigneeCountry={consigneeDetails.consigneeCountry}
								setSelectedCosigneeCountry={setSelectedCosigneeCountry}
								isMandatory={true}
								setError={setError}
							/>
							<span className='form-item__error'>
								{error && error['consigneeCountry']
									? t(error['consigneeCountry'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='consigneeZipCode'>
								{t('COMMON.ZIP_CODE')}
							</label>
							<input
								id='consigneeZipCode'
								name='consigneeZipCode'
								type='text'
								className='form-item__field'
								placeholder='Zip code'
								maxLength='10'
								value={consigneeDetails.consigneePostalCode ? consigneeDetails.consigneePostalCode.toUpperCase(): ''}
								onChange={(e) => {
									setConsigneeDetails({
										...consigneeDetails,
										consigneePostalCode: e.target.value,
									})
								}}
								onBlur={(e) => {
									validatePostalCodeIfPresent(
										e.target.value,
										setError,
										'consigneeZipCode'
									)
								}}
								aria-invalid={hasError('consigneeZipCode', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeZipCode']
									? t(error['consigneeZipCode'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label
								className='form-item__label'
								htmlFor='consigneeAccountNumber'
							>
								{t('COMMON.ACCNT_NO')}
							</label>
							<input
								id='consigneeAccountNumber'
								name='consigneeAccountNumber'
								type='text'
								className='form-item__field'
								placeholder='Account number'
								maxLength='14'
								value={consigneeDetails.consigneeAccountNumber ? consigneeDetails.consigneeAccountNumber.toUpperCase() : ''}
								onChange={(e) => {
									setConsigneeDetails({
										...consigneeDetails,
										consigneeAccountNumber: e.target.value,
									})
								}}
								onBlur={(e) => {
									validateAlphaNumericIfPresent(
										e.target.value,
										setError,
										'consigneeAccountNumber'
									)
								}}
								aria-invalid={hasError('consigneeAccountNumber', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeAccountNumber']
									? t(error['consigneeAccountNumber'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>

					<div className='form-row mt-lg-4 mb-2 mb-lg-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='consigneeTelephone'>
								{t('COMMON.TEL_HEAD')}
							</label>
							<input
								id='consigneeTelephone'
								name='consigneeTelephone'
								type='text'
								className='form-item__field'
								placeholder='Telephone'
								maxLength='35'
								value={consigneeDetails.consigneePhone ? consigneeDetails.consigneePhone.toUpperCase(): ''}
								onChange={(e) => {
									setConsigneeDetails({
										...consigneeDetails,
										consigneePhone: e.target.value,
									})
								}}
								onBlur={(e) => {
									validatePhoneNumberIfPresent(
										e.target.value,
										setError,
										'consigneeTelephone'
									)
								}}
								aria-invalid={hasError('consigneeTelephone', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeTelephone']
									? t(error['consigneeTelephone'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>

					<div className='form-row mt-lg-4 mb-3'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='cconsigneeMail'>
								{t('COMMON.EMAIL_HEAD')}
							</label>
							<input
								id='cconsigneeMail'
								name='consigneeMail'
								type='text'
								className='form-item__field'
								placeholder='Email address'
								maxLength='70'
								value={consigneeDetails.consigneeMail ? consigneeDetails.consigneeMail.toUpperCase():''}
								onChange={(e) => {
									setConsigneeDetails({
										...consigneeDetails,
										consigneeMail: e.target.value,
									})
								}}
								onBlur={(e) => {
									validateEmailIfPresent(
										e.target.value,
										setError,
										'consigneeEmail'
									)
								}}
								aria-invalid={hasError('consigneeEmail', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeEmail']
									? t(error['consigneeEmail'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ParticipantDetails
