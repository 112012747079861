import sessionKeys from '../../constants/sessionKeys'
import { clearItemFromLocalStorage, getBase64Decoded, setToLocalStorage } from '../../utils/StorageUtils'
import { AUTH } from '../constants'

const initialState = {
	principal: null,
	refreshToken: null,
	accessToken: null,
	isRefreshing: false,
}

export default function (state = initialState, action) {
	switch (action.type) {
		case AUTH.SET_REFRESH_TOKEN:
			const {refreshToken} = action
			setToLocalStorage(sessionKeys.REFRESH_TOKEN, refreshToken)
			return {
				...state,
				refreshToken,
			}
		case AUTH.SET_ACCESS_TOKEN:
			const {accessToken} = action
			let principal = null
			if (accessToken) {
				setToLocalStorage(sessionKeys.ACCESS_TOKEN, accessToken)
				try{
					const encodedPayload = accessToken.split('.')[1]
					principal =
					encodedPayload
							? getBase64Decoded(encodedPayload)
							: null
					principal = {...principal, agentCode: principal.customerCode}
					setToLocalStorage(sessionKeys.SUBJECT, JSON.stringify(principal))
				} catch (err) {
					console.log('ERROR Parsing accessToken :'+err)
				}	
			}
			return {
				...state,
				accessToken,
				principal,
			}
		case AUTH.SET_IS_REFRESHING:
			const {isRefreshing} = action
			return {
				...state,
				isRefreshing,
			}
		case AUTH.CLEAR:
			clearItemFromLocalStorage(sessionKeys.REFRESH_TOKEN)
			clearItemFromLocalStorage(sessionKeys.ACCESS_TOKEN)
			clearItemFromLocalStorage(sessionKeys.SUBJECT)
			clearItemFromLocalStorage(sessionKeys.SSO_REFRESH_URL)
			clearItemFromLocalStorage(sessionKeys.SSO_TYPE)
			clearItemFromLocalStorage(sessionKeys.PERMANENT_BKG_FLG)
			clearItemFromLocalStorage(sessionKeys.USER_DETAILS)
			clearItemFromLocalStorage(sessionKeys.PLACI_USER_DETAILS)
			clearItemFromLocalStorage(sessionKeys.AGENT_COUNTRY)
			return {
				principal: null,
				refreshToken: null,
				accessToken: null,
				isRefreshing: false,
			}
		default:
			return state
	}
}
