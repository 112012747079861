import React, { useState, useEffect } from 'react'
import {
	DETAILS_APPLY_FOR,
	FORM_FIELDS,
	LOOSE_FORM_FIELDS,
	WEIGHT_UNIT_SELECT_ITEMS_WO_TON,
} from '../../constants'
import { Dropdown } from 'primereact/dropdown'
import {
	WEIGHT_UNIT_SELECT_ITEMS,
	DIMENSION_UNIT_SELECT_ITEMS,
	DIMENSION_UNIT,
} from '../../constants'
import {
	validateLineItemWeights,
	validateTotalWeight,
	validateLooseDetails,
} from '../SearchPanel/formUtils'
import trashIcon from '../../assets/images/trash.svg'
import { Button } from 'primereact/button'
import calculateKgWeight from '../../utils/calculateKgWeight'
import './loose-line-item.scss'
import ExportToExcel from './Content'
import ExcelReader from './LooseUpload'
import { calculateWeightToKg, round, allowNumberOnly} from '../../utils/common'
import { useTranslation } from 'react-i18next'
import infoIcon from '../../assets/images/info_icon.svg'
const LooseLineItem = ({
	looseDetails,
	setLooseDetails,
	totalWeight,
	setTotalWeight,
	setLooseItemsGrossWeight,
	weightUnit,
	setWeightUnit,
	dimensionUnit,
	setDimensionUnit,
	isAllStackable,
	setAllStackable,
	isAllTiltable,
	setAllTiltable,
	handleAddLineItem,
	setTotalPieces,
	error,
	setError,
	setTotalVolumeInMeters,
	setActualLooseTotalVolume,
	setTotalWeightInKg,
	totalWeightInKilo,
	totalVolumeinMtrs,
	setChargeableWeight,
	calculatedTotalWeight,
	setCalculatedTotalWeight,
	showValidation,
	disableWeightUnit,
	hideLooseUpload,
	draftCallback,
	excludeTon,
	captureAwb,
}) => {

	const [t,i18n] = useTranslation(['labels','errors'])

	const [weightWarning, setWeightWarning] = useState(null)

	const [showOpWeight, setShowOpweight] = useState(false)

	useEffect(() => {
		handleSummaryChange(looseDetails)
		handleWeightInput(looseDetails)
	}, [looseDetails, weightUnit, dimensionUnit, totalWeight])

	useEffect(() => {
		let chargeableWeight = 0
		let volumetricWeight = 0
		if (totalVolumeinMtrs) {
			//volumetricWeight = round(totalVolumeinMtrs, 2) * 166.6667
			volumetricWeight = Number(totalVolumeinMtrs).toFixed(2) * 166.6667
		}
		if (totalWeightInKilo || volumetricWeight) {
			chargeableWeight = Math.max(totalWeightInKilo * 1, volumetricWeight * 1)
		}
		setChargeableWeight(chargeableWeight)
	}, [totalVolumeinMtrs, totalWeightInKilo])

	useEffect(() => {
		setLooseItemsGrossWeight(calculatedTotalWeight)
		const weight = calculatedTotalWeight ? calculatedTotalWeight : totalWeight
		const totalWeightinKg = calculateWeightToKg(weight, weightUnit)
		setTotalWeightInKg(totalWeightinKg)
		if (draftCallback) {
			draftCallback(true)
		}
	}, [totalWeight, calculatedTotalWeight, weightUnit])

	const handleItemChange = (key, value, index) => {
		const templooseDetails = JSON.parse(JSON.stringify(looseDetails))
		if (key == 'stackable' || key == 'turnable') {
			templooseDetails[index][key] = !value
		} else {
			templooseDetails[index][key] = value
		}
		setLooseDetails([...templooseDetails])
		if (key == 'weightType') {
			handleWeightInput(templooseDetails)
		}
	}

	const handleWeightInput = (LooseDetails) => {
		const calculatedTotalWeight = [...LooseDetails, {}].reduce((curr, next) => {
			return {
				weight:
					(curr.weightType === DETAILS_APPLY_FOR.PER_ITEM
						? (curr.pieces ? curr.pieces * 1 : 0) *
						(curr.weight ? curr.weight * 1 : 0)
						: curr.weight
							? curr.weight * 1
							: 0) +
					(next.weightType === DETAILS_APPLY_FOR.PER_ITEM
						? (next.pieces ? next.pieces * 1 : 0) *
						(next.weight ? next.weight * 1 : 0)
						: next.weight
							? next.weight * 1
							: 0),
				pieces: 1,
			}
		}).weight
		if (showValidation) {
			let totalWeightError = validateTotalWeight(
				totalWeight,
				calculatedTotalWeight
			)
			setError({ ...error, [FORM_FIELDS.TOTAL_WEIGHT]: totalWeightError })
		}
		// setWeightWarning(
		// 	validateLineItemWeightSum(calculatedTotalWeight, totalWeight)
		// )
		setCalculatedTotalWeight(calculatedTotalWeight)
	}

	const handleLineItemWeightRemoval = () => {
		let looseDetailsErrors =
			error && error[FORM_FIELDS.LOOSE_ITEMS]
				? error[FORM_FIELDS.LOOSE_ITEMS]
				: []
		looseDetailsErrors = validateLineItemWeights(
			looseDetails,
			looseDetailsErrors
		)
		setError({ ...error, [FORM_FIELDS.LOOSE_ITEMS]: looseDetailsErrors })
	}

	const handleSummaryChange = (LooseDetails) => {
		let quantity = 0
		let totalVolumeinMeters = 0
		let actualTotalVolume = 0
		LooseDetails.forEach(function (element) {
			if (element.pieces) {
				quantity += element.pieces * 1
			}
			if (element.pieces && element.length && element.width && element.height) {
				actualTotalVolume +=
					parseFloat(element.width * element.length * element.height) *
					parseInt(element.pieces)
			}
			if (element.pieces && element.length && element.width && element.height) {
				if (dimensionUnit == DIMENSION_UNIT.INCHES) {
					totalVolumeinMeters +=
						parseFloat(
							element.width *
							0.0254 *
							(element.length * 0.0254) *
							(element.height * 0.0254)
						) * parseInt(element.pieces)
				}
				if (dimensionUnit == DIMENSION_UNIT.CENTIMETERS) {
					totalVolumeinMeters +=
						parseFloat(
							element.width *
							0.01 *
							(element.length * 0.01) *
							(element.height * 0.01)
						) * parseInt(element.pieces)
				}
			}
		})
		if (totalVolumeinMeters && totalVolumeinMeters < 0.01) {
			totalVolumeinMeters = 0.0
		}
		setTotalVolumeInMeters(totalVolumeinMeters)
		setActualLooseTotalVolume(actualTotalVolume)
		setTotalPieces(quantity)
	}
	const handleRemoveItem = (index) => {
		const looseDetailsTemp = looseDetails.filter((itemDetail, curr) => {
			return index !== curr
		})
		setLooseDetails(looseDetailsTemp)
		handleWeightInput(looseDetailsTemp)
	}

	const clearError = (key) => {
		const tempError = error ? { ...error } : null
		if (tempError) {
			tempError[key] = null
			setError(tempError)
		}
	}
	const clearItemError = (key, index) => {
		const tempError = error ? { ...error } : null
		if (
			tempError &&
			tempError[FORM_FIELDS.LOOSE_ITEMS] &&
			tempError[FORM_FIELDS.LOOSE_ITEMS][index]
		) {
			tempError[FORM_FIELDS.LOOSE_ITEMS][index][key] = null
			setError(tempError)
		}
	}

	const handleClickAdd = (e) => {
		
		const tempCalculatedSumError =
			totalWeight && calculatedTotalWeight * 1 == totalWeight * 1
				? 'Sum of dimension level weight(s) cannot exceed the Total weight.'
				: weightWarning
		handleAddLineItem(e)
		// setTimeout(() => {
		// 	setWeightWarning(tempCalculatedSumError)
		// }, 300)
	}
	//============== for bulk upload ===============//
	const isEmpty = looseDetails?.map((item) => {
		if (
			item.pieces === '' &&
			item.length === '' &&
			item.height === '' &&
			item.stackable === true &&
			item.turnable === true &&
			item.weight === '' &&
			item.weightType === 'PER_ITEM' &&
			item.width === ''
		) {
			return true
		} else return false
	})

	const checkIsEmpty = () => {
		let isLooseEmpty = true
		looseDetails?.filter((item) => {
			if (
				item.pieces !== '' ||
				item.length !== '' ||
				item.height !== '' ||
				item.weight != '' ||
				item.width != '' ||
				item.weightType !== 'PER_ITEM' 
			) {
				isLooseEmpty = false
			}
		})
		return isLooseEmpty;
	}

	const onReadExcel = (val, weightUnit, dimensionUnit) => {
		//============== for bulk dimension & weight unit ===============//
		if (dimensionUnit === 'cm') {
			setDimensionUnit('C')
		} else if (dimensionUnit === 'inch') {
			setDimensionUnit('I')
		} else return

		if (weightUnit === 'kg') {
			setWeightUnit('K')
		} else if (weightUnit === 'lb') {
			setWeightUnit('L')
		} else if (weightUnit === 't') {
			setWeightUnit('T')
		} else return
		//=================================================//
		let tempLooseDetails = [...looseDetails]
		if (captureAwb) {
			tempLooseDetails = []
		}

		if (checkIsEmpty()) {
			tempLooseDetails = val
			setLooseDetails(val)
		} else {
			tempLooseDetails = tempLooseDetails.concat(val)
			setLooseDetails(tempLooseDetails)
		}
		let looseDetailsErrors = validateLooseDetails(tempLooseDetails)
		const hasError = looseDetailsErrors.some((item) => (item ? true : false))
		error[FORM_FIELDS.LOOSE_ITEMS] = hasError ? looseDetailsErrors : null
	}
	//==============================================//

	useEffect(() => {
		if (isAllStackable === undefined) {
			setAllStackable(false)
		}
		if (isAllTiltable === undefined) {
			setAllTiltable(false)
		}
	}, [isAllTiltable, isAllStackable])

	/*=================== OPERATIONAL WEIGHT=========================*/

	const handleOperationalWeight = () => {
		setShowOpweight(!showOpWeight)
	}


	return (
		<div>
			{looseDetails &&
				looseDetails.length &&
				looseDetails.map((item, key) => {
					const piecesError =
						error &&
							error[FORM_FIELDS.LOOSE_ITEMS] &&
							error[FORM_FIELDS.LOOSE_ITEMS][key] &&
							error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.PIECES]
							? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.PIECES]
							: null
					const lengthError =
						error &&
							error[FORM_FIELDS.LOOSE_ITEMS] &&
							error[FORM_FIELDS.LOOSE_ITEMS][key] &&
							error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.LENGTH]
							? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.LENGTH]
							: null
					const widthError =
						error &&
							error[FORM_FIELDS.LOOSE_ITEMS] &&
							error[FORM_FIELDS.LOOSE_ITEMS][key] &&
							error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.WIDTH]
							? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.WIDTH]
							: null
					const heightError =
						error &&
							error[FORM_FIELDS.LOOSE_ITEMS] &&
							error[FORM_FIELDS.LOOSE_ITEMS][key] &&
							error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.HEIGHT]
							? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.HEIGHT]
							: null
					const weightError =
						error &&
							error[FORM_FIELDS.LOOSE_ITEMS] &&
							error[FORM_FIELDS.LOOSE_ITEMS][key] &&
							error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.WEIGHT]
							? error[FORM_FIELDS.LOOSE_ITEMS][key][LOOSE_FORM_FIELDS.WEIGHT]
							: null
					return (
						<div
							className={`item-group search-panel__data mixbooking-lineitem ${looseDetails.length - 1 === key ? 'last-item' : ''
								}`}
							key={key}
						>
							<div className='form-row m-b-20'>
								<div
									className={`form-group pieces-div col-12 col-lg-auto ${piecesError ? 'red-border' : ''
										}`}
								>
									<div className='row mb-0'>
									<div
									className="form-group mb-0 col-6 col-lg-12">
									<label className="additional-style">{t('COMMON.PCS')}</label>
									<input
										type='text'
										className='form-control'
										value={item.pieces}
										id={`quantity-${key}`}
										onChange={(e) => {
											const value = e.target.value.replace(/[^\d]/,'');
											if (value.length <= 5) {
												handleItemChange('pieces', value, key)
											}
										}}
										onFocus={(e) =>
											clearItemError(LOOSE_FORM_FIELDS.PIECES, key)
										}
										onBlur={(e) => {
											if (!Number(item.pieces)) {
												handleItemChange('pieces', '', key)
											}
											handleWeightInput(looseDetails)
										}}
									/>
									<span className='search-panel__errormsg'>
										{piecesError ? t(piecesError, { ns: 'errors' }) : ''}
									</span>
								</div>
								</div>
								</div>
								<div
									className={`form-group col-6 col-lg-1 ${lengthError ? 'red-border' : ''
										}`}
								>
									<label className="additional-style">{t('COMMON.LN')}</label>
									<input
										type='text'
										className='form-control'
										value={item['length']}
										id={`length-${key}`}
										onChange={(e) => {
											const value = e.target.value.replace(/[^\d]/,'');
											if (value.length <= 5) {
												handleItemChange('length', value, key)
											}
										}}
										onFocus={(e) =>
											clearItemError(LOOSE_FORM_FIELDS.LENGTH, key)
										}
										onBlur={(e) => {
											if (!Number(item['length'])) {
												handleItemChange('length', '', key)
											}
											handleSummaryChange(looseDetails)
										}}
									/>
									<span className='search-panel__errormsg'>
										{lengthError ? t(lengthError, { ns: 'errors' }) : ''}
									</span>
								</div>

								<div
									className={`form-group col-6 col-lg-1 ${widthError ? 'red-border' : ''
										}`}
								>
									<label className="additional-style">{t('COMMON.WD')}</label>
									<input
										type='text'
										className='form-control'
										id={`width-${key}`}
										value={item.width}
										onChange={(e) => {
											const value = e.target.value.replace(/[^\d]/,'');
											if (value.length <= 5) {
												handleItemChange('width', value, key)
											}
										}}
										onFocus={(e) =>
											clearItemError(LOOSE_FORM_FIELDS.WIDTH, key)
										}
										onBlur={(e) => {
											if (!Number(item['width'])) {
												handleItemChange('width', '', key)
											}
											handleSummaryChange(looseDetails)
										}}
									/>
									<span className='search-panel__errormsg'>
										{widthError ? t(widthError, { ns: 'errors' }) : ''}
									</span>
								</div>
								<div
									className={`form-group col-6 col-lg-1 ${heightError ? 'red-border' : ''
										}`}
								>
									<label className="additional-style">{t('COMMON.HT')}</label>
									<input
										type='text'
										className='form-control'
										id={`height-${key}`}
										value={item.height}
										onChange={(e) => {
											const value = e.target.value.replace(/[^\d]/,'');
											if (value.length <= 5) {
												handleItemChange('height', value, key)
											}
										}}
										onFocus={(e) =>
											clearItemError(LOOSE_FORM_FIELDS.HEIGHT, key)
										}
										onBlur={(e) => {
											if (!Number(item.height)) {
												handleItemChange('height', '', key)
											}
											handleSummaryChange(looseDetails)
										}}
									/>
									<span className='search-panel__errormsg'>
										{heightError ? t(heightError, { ns: 'errors' }) : ''}
									</span>
								</div>
								<div className='form-group col-6 col-lg-1 mr-0'>
									<label className="additional-style">&nbsp;</label>
									<Dropdown
										value={dimensionUnit}
										options={DIMENSION_UNIT_SELECT_ITEMS}
										onChange={(e) => setDimensionUnit(e.target.value)}
										className={'form-control'}
										disabled={key ? true : false}
										dataKey={item.dimensionUnit}
									/>
								</div>
								<div
									className={`form-group col-6 col-lg-auto weight-div ${weightError ? 'red-border' : ''
										}`}
								>
									<label className="additional-style">{t('COMMON.WT')}  </label>
									<div className='search-panel__double_element'>
										<input
											type='text'
											className='form-control borderRadius-R0'
											value={item.weight}
											onKeyDown={(evt) => allowNumberOnly(evt)}
											onChange={(e) => {
												const value = e.target.value.replace(/[^0-9.]/g,'');
												if (value.length <= 9 && (
													!value.split('.')[1] ||
													!value.split('.')[1].length ||
													value.split('.')[1].length < 3
												) &&
												(value.split('.').length <=2)
												) {
													handleItemChange('weight', value, key)
												}
											}}
											onFocus={(e) =>
												clearItemError(LOOSE_FORM_FIELDS.WEIGHT, key)
											}
											onBlur={(e) => {
												handleWeightInput(looseDetails)
												if (
													(!e.target.value || !e.target.value.length) &&
													!looseDetails.some((detail) => detail.weight)
												) {
													handleLineItemWeightRemoval()
												}
											}}
										/>
										<div className='m-r-10 borderRadius-L0 border-left0 dropdown_div'>
											<Dropdown
												className='form-control cust-dropdown'
												value={weightUnit}
												options={excludeTon ? WEIGHT_UNIT_SELECT_ITEMS_WO_TON : WEIGHT_UNIT_SELECT_ITEMS}
												onChange={(e) => {
													setWeightUnit(e.target.value)
													if (draftCallback) {
														draftCallback(true)
													}
												}}
												disabled={disableWeightUnit || key ? true : false}
											/>
										</div>
									</div>
									<span className='search-panel__errormsg'>
										{weightError ? t(weightError, { ns: 'errors' }) : ''}
									</span>
								</div>

								<div className='form-group col-12 col-lg-1 no-wrap-type'>
									<label className="additional-style">{t('COMMON.WT_TYPE')}</label>
									<div className='d-flex d-lg-block border-0 p-l-0 p-t-0'>
										<div className='flex-fill l-radio'>
											<input
												type='radio'
												id={`perItem-${key}`}
												name='radio'
												value={DETAILS_APPLY_FOR.PER_ITEM}
												checked={item.weightType === DETAILS_APPLY_FOR.PER_ITEM}
												onChange={(e) => {
													handleItemChange('weightType', e.target.value, key)
												}}
											/>
											<label
												className={`mb-0 ${item.weightType === DETAILS_APPLY_FOR.PER_ITEM
													? 'checked'
													: ''
													}`}
												htmlFor={`perItem-${key}`}
											>
											{t('COMMON.PER_ITEM')}
											</label>
										</div>
										<div className='flex-fill l-radio'>
											<input
												type='radio'
												id={`total-${key}`}
												name='radio'
												value={DETAILS_APPLY_FOR.TOTAL}
												checked={item.weightType === DETAILS_APPLY_FOR.TOTAL}
												onChange={(e) => {
													handleItemChange('weightType', e.target.value, key)
													handleWeightInput(looseDetails)
													handleSummaryChange(looseDetails)
												}}
											/>
											<label
												className={`mb-0 ${item.weightType === DETAILS_APPLY_FOR.TOTAL
													? 'checked'
													: ''
													}`}
												htmlFor={`total-${key}`}
											>
												{t('COMMON.TOTAL')}
											</label>
										</div>
									</div>
								</div>
								<div className='form-group col-12 col-lg-2 custom-pl-4 mb-0'>
									<label className="additional-style">{t('COMMON.PCKG_TYPE')}</label>
									<div className='d-flex d-lg-block border-0 p-l-0'>
										<div className='flex-fill l-checkbox'>
											<input
												id={`stackable-${key}`}
												type='checkbox'
												disabled={isAllStackable}
												checked={!item.stackable}
												onChange={(e) =>
													handleItemChange('stackable', e.target.checked, key)
												}
											/>
											<label className="mb-0" htmlFor={`stackable-${key}`}>
												{t('COMMON.NON_STACKABLE')}
											</label>
										</div>
										<div className='flex-fill l-checkbox'>
											<input
												id={`turnable-${key}`}
												type='checkbox'
												disabled={isAllTiltable}
												checked={!item.turnable}
												onChange={(e) =>
													handleItemChange('turnable', e.target.checked, key)
												}
											/>
											<label className="mb-0" htmlFor={`turnable-${key}`}>
												{t('COMMON.NON_TILTABLE')}
											</label>
										</div>
									</div>
								</div>
								{looseDetails.length > 1 ? (
									<div class='delete-section col-12 col-lg-auto pl-3'>
										<img
											onClick={(e) => handleRemoveItem(key)}
											src={trashIcon}
											alt='Trash'
											title='Trash'
										/>
										<span>{t('COMMON.DELETE')}</span>
									</div>
								) : (
									<></>
								)}
							</div>
						</div>
					)
				})}
			<div className='form-row'>
				<div
					className='form-group col-12 col-lg-7 m-b-0 d-lg-flex flex-lg-wrap mt-2'
				>
					<div className='col-12 col-lg-6 pr-lg-0'>
						<Button
							label={t('COMMON.ADD_ANOTHER')}
							icon='pi pi-plus'
							className='button__another d-block custom-xs'
							type='button'
							onClick={(e) => handleClickAdd(e)}
						/>
					</div>
					<div className='col-12 col-lg-4 pl-lg-0 excel-upload-col'>
						{!hideLooseUpload && (
							<React.Fragment>
								<ExcelReader onRead={onReadExcel} pageName={captureAwb ? 'CAPTURE': null } />
							</React.Fragment>
						)}
						<span className='search-panel__errormsg'>
							{weightWarning ? weightWarning : ''}
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LooseLineItem
