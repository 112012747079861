import React, { useState, useEffect } from 'react'
import {
	validateNumericIfPresent,
	validateAlphaRequired,
	validateRequired,
	validateEmailIfPresent,
	validatePhoneNumberIfPresent,
	hasError,
	removeError,
	validateAlphaNumericRequired,
	validateZipCodeIfPresent,
	validateAddressRequired,
	validateAddressIfPresent,
	validateAlphaIfPresent,
} from '../captureAwbUtils'
import CountryAutocomplete from '../../CountryAutocomplete'
import { AUTOCOMPLETE_TYPE_COUNTRY } from '../../../constants'
import { useTranslation } from 'react-i18next'
import MESSAGES from '../../../constants/Messages'
const ParticipantDetails = ({
	awbDetails,
	setAwbDetails,
	error,
	setError,
	showNotifyList,
	setShowNotifyList,
}) => {
	//for multilingual
	const { t } = useTranslation(['labels', 'errors'])

	let shipperDetails = awbDetails.shipperDetails
	let consigneeDetails = awbDetails.consigneeDetails

	//console.log(awbDetails,'awbDetails')

	const [notifyList, setNotifyList] = useState(
		awbDetails.notifyList
			? awbDetails.notifyList
			: {
				notifyShipper: {
					checked: false,
				},
				notifyConsignee: {
					checked: false,
				},
				notifyOther: {
					checked: true,
					name: '',
					telephoneNo: '',
					email: '',
					address: '',
					city: '',
					country: '',
				},
			}
	)

	useEffect(() => {
		setAwbDetails((prevState) => ({
			...prevState,
			notifyList: notifyList,
		}))
	}, [notifyList])

	const handleNotify = (evnt) => {
		const key = evnt.target.id
		const isChecked = evnt.target.checked
		let details = {
			checked: isChecked,
		}
		setError((prevState) => ({
			...prevState,
			notifyList: '',
		}))
		if (key === 'notifyOther') {
			details = {
				checked: isChecked,
				name: '',
				telephoneNo: '',
				email: '',
			}
		} else if (key === 'notifyShipper') {
			if (
				isChecked &&
				shipperDetails.emailAddress.trim() === '' &&
				shipperDetails.telephoneNo.trim() === ''
			) {
				setError((prevState) => ({
					...prevState,
					notifyShipper: MESSAGES.SHIPPER_NOTIFICATION_MSG,
				}))
			} else {
				setError((prevState) => ({
					...prevState,
					notifyShipper: '',
				}))
			}
		} else if (key === 'notifyConsignee') {
			if (
				isChecked &&
				consigneeDetails.emailAddress.trim() === '' &&
				consigneeDetails.telephoneNo.trim() === ''
			) {
				setError((prevState) => ({
					...prevState,
					notifyConsignee: MESSAGES.CONSGN_NOTIFICATION_MSG,
				}))
			} else {
				setError((prevState) => ({
					...prevState,
					notifyConsignee: '',
				}))
			}
		}

		setNotifyList((prevState) => ({
			...prevState,
			[key]: details,
		}))
	}

	const handleShowNotificationDetails = () => {
		setShowNotifyList(!showNotifyList)
	}

	const setSelectedShipperCountry = (val) => {
		shipperDetails.countryCode = val
		setAwbDetails((prevState) => ({
			...prevState,
			shipperDetails: shipperDetails,
		}))
	}

	const setSelectedConsigneeCountry = (val) => {
		consigneeDetails.countryCode = val
		setAwbDetails((prevState) => ({
			...prevState,
			consigneeDetails: consigneeDetails,
		}))
	}

	const setSelectedNotifyListOtherCountry = (val) => {
		let notifyOther = notifyList.notifyOther
		notifyOther.country = val
		setNotifyList((prevState) => ({
			...prevState,
			notifyOther: notifyOther,
		}))
	}

	return (
		<div className='mt-md-3 mt-0 mb-md-3 mb-0 participant-details-wrapper'>
			<div className='form-row pl-md-2 pl-0'>
				<div className='col-12'>
					<h4 className='section-heading'>{t('COMMON.PAR_DTLS')}</h4>
				</div>
			</div>
			<div className='form-row'>
				<div className='col-12 col-lg-4'>
					<h5 className='section-sub-heading pl-md-1 pl-0'>{t('COMMON.SHP_DTLS')}</h5>
					<div className='form-row mt-4 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperName'>
								{t('COMMON.NAME')}
							</label>
							<input
								id='shipperName'
								name='shipperName'
								type='text'
								className='form-item__field'
								placeholder='Name'
								maxLength='70'
								value={shipperDetails.name ? shipperDetails.name.toUpperCase() : ''}
								onChange={(e) => {
									shipperDetails.name = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										shipperDetails: shipperDetails,
									}))
								}}
								onBlur={(e) => {
									validateRequired(e.target.value, setError, 'shipperName', t('COMMON.NAME'))
								}}
								aria-invalid={hasError('shipperName', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperName']
									? t(error['shipperName'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperAddress'>
								{t('COMMON.ADDRS')}
							</label>
							<input
								id='shipperAddress'
								name='shipperAddress'
								type='text'
								className='form-item__field'
								placeholder='Address'
								value={shipperDetails.address ? shipperDetails.address.toUpperCase() : ''}
								maxLength='70'
								onChange={(e) => {
									shipperDetails.address = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										shipperDetails: shipperDetails,
									}))
								}}
								onBlur={(e) => {
									validateRequired(
										e.target.value,
										setError,
										'shipperAddress',
										t('COMMON.ADDRS')
									)
								}}
								aria-invalid={hasError('shipperAddress', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperAddress']
									? t(error['shipperAddress'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperCity'>
								{t('COMMON.CITY')}
							</label>
							<input
								id='shipperCity'
								name='shipperCity'
								type='text'
								className='form-item__field'
								placeholder='City'
								value={shipperDetails.city ? shipperDetails.city.toUpperCase() : ''}
								maxLength='70'
								onChange={(e) => {
									shipperDetails.city = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										shipperDetails: shipperDetails,
									}))
								}}
								onBlur={(e) => {
									validateRequired(e.target.value, setError, 'shipperCity', t('COMMON.CITY'))
								}}
								aria-invalid={hasError('shipperCity', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperCity']
									? t(error['shipperCity'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperState'>
								{t('COMMON.STATE')}
							</label>
							<input
								id='shipperState'
								name='shipperState'
								type='text'
								className='form-item__field'
								placeholder='State'
								value={shipperDetails.state ? shipperDetails.state.toUpperCase() : ''}
								maxLength='70'
								onChange={(e) => {
									shipperDetails.state = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										shipperDetails: shipperDetails,
									}))
								}}
								onBlur={(e) => {
									validateAlphaIfPresent(e.target.value, setError, 'shipperState')
								}}
								aria-invalid={hasError('shipperState', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperState']
									? t(error['shipperState'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className={`col-12 form-item form-item--text ${hasError('shipperCountry', error) ? 'error-field' : ''
								}`}
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperCountry'>
								{t('COMMON.CNTRY')}
							</label>
							<CountryAutocomplete
								autoCompleteType={AUTOCOMPLETE_TYPE_COUNTRY.SHIPPER}
								selectedShipperCountry={shipperDetails.countryCode}
								selectedCosigneeCountry={consigneeDetails.countryCode}
								setSelectedShipperCountry={setSelectedShipperCountry}
								isMandatory={true}
								setError={setError}
							/>
							{/*<input
								id='shipperCountry'
								name='shipperCountry'
								type='text'
								className='form-item__field'
								placeholder='Country'
								maxLength='30'
								value={shipperDetails.countryCode}
								onChange={(e) => {
									shipperDetails.countryCode = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										shipperDetails: shipperDetails,
									}))
								}}
								onBlur={(e) => {
									validateAlphaRequired(
										e.target.value,
										setError,
										'shipperCountry'
									)
								}}
								aria-invalid={hasError('shipperCountry', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>*/}
							<span className='form-item__error'>
								{error && error['shipperCountry']
									? t(error['shipperCountry'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperZipCode'>
								{t('COMMON.ZIP_CODE')}
							</label>
							<input
								id='shipperZipCode'
								name='shipperZipCode'
								type='text'
								className='form-item__field'
								placeholder='Zip code'
								value={shipperDetails.zipCode ? shipperDetails.zipCode.toUpperCase() :''}
								maxLength='11'
								onChange={(e) => {
									shipperDetails.zipCode = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										shipperDetails: shipperDetails,
									}))
								}}
								onBlur={(e) => {
									validateZipCodeIfPresent(
										e.target.value,
										setError,
										'shipperZipCode'
									)
								}}
								aria-invalid={hasError('shipperZipCode', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperZipCode']
									? t(error['shipperZipCode'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label
								className='form-item__label'
								htmlFor='shipperAccountNumber'
							>
								{t('COMMON.ACCNT_NO')}
							</label>
							<input
								id='shipperAccountNumber'
								name='shipperAccountNumber'
								type='text'
								className='form-item__field'
								placeholder='Account number'
								maxLength='14'
								value={shipperDetails.accountNumber ? shipperDetails.accountNumber.toUpperCase() : ''}
								onChange={(e) => {
									shipperDetails.accountNumber = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										shipperDetails: shipperDetails,
									}))
								}}
								onBlur={(e) => {
									validateNumericIfPresent(
										e.target.value,
										setError,
										'shipperAccountNumber'
									)
								}}
								aria-invalid={hasError('shipperAccountNumber', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperAccountNumber']
									? t(error['shipperAccountNumber'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>

					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperTelephone'>
								{t('COMMON.TEL_HEAD')}
							</label>
							<input
								id='shipperTelephone'
								name='shipperTelephone'
								type='text'
								className='form-item__field'
								placeholder='Telephone'
								maxLength='20'
								value={shipperDetails.telephoneNo ? shipperDetails.telephoneNo.toUpperCase() : ''}
								onChange={(e) => {
									shipperDetails.telephoneNo = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										shipperDetails: shipperDetails,
									}))
								}}
								onBlur={(e) => {
									const status = validatePhoneNumberIfPresent(
										e.target.value,
										setError,
										'shipperTelephone'
									)
									if (e.target.value !== '' && status) {
										setError((prevState) => ({
											...prevState,
											notifyShipper: '',
										}))
									}
								}}
								aria-invalid={hasError('shipperTelephone', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperTelephone']
									? t(error['shipperTelephone'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>

					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='shipperEmail'>
								{t('COMMON.EMAIL_HEAD')}
							</label>
							<input
								id='shipperEmail'
								name='shipperEmail'
								type='text'
								className='form-item__field'
								placeholder='Email address'
								maxLength='50'
								value={shipperDetails.emailAddress ? shipperDetails.emailAddress.toUpperCase() : ''}
								onChange={(e) => {
									shipperDetails.emailAddress = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										shipperDetails: shipperDetails,
									}))
								}}
								onBlur={(e) => {
									const status = validateEmailIfPresent(
										e.target.value,
										setError,
										'shipperEmail'
									)
									if (e.target.value !== '' && status) {
										setError((prevState) => ({
											...prevState,
											notifyShipper: '',
										}))
									}
								}}
								aria-invalid={hasError('shipperEmail', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['shipperEmail']
									? t(error['shipperEmail'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
				</div>
				<div className='col-12 col-lg-1'></div>
				<div className='col-12 col-lg-4'>
					<h5 className='section-sub-heading pl-md-1 pl-0'>{t('COMMON.CON_DTLS')}</h5>
					<div className='form-row mt-4 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='consigneeName'>
								{t('COMMON.NAME')}
							</label>
							<input
								id='consigneeName'
								name='consigneeName'
								type='text'
								className='form-item__field'
								placeholder='Name'
								maxLength='70'
								value={consigneeDetails.name ? consigneeDetails.name.toUpperCase() : ''}
								onChange={(e) => {
									consigneeDetails.name = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										consigneeDetails: consigneeDetails,
									}))
								}}
								onBlur={(e) => {
									validateRequired(
										e.target.value,
										setError,
										'consigneeName',
										t('COMMON.NAME')
									)
								}}
								aria-invalid={hasError('consigneeName', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeName']
									? t(error['consigneeName'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='consigneeAddress'>
								{t('COMMON.ADDRS')}
							</label>
							<input
								id='consigneeAddress'
								name='consigneeAddress'
								type='text'
								className='form-item__field'
								placeholder='Address'
								maxLength='70'
								value={consigneeDetails.address ? consigneeDetails.address.toUpperCase() : ''}
								onChange={(e) => {
									consigneeDetails.address = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										consigneeDetails: consigneeDetails,
									}))
								}}
								onBlur={(e) => {
									validateRequired(
										e.target.value,
										setError,
										'consigneeAddress',
										t('COMMON.ADDRS')
									)
								}}
								aria-invalid={hasError('consigneeAddress', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeAddress']
									? t(error['consigneeAddress'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='consigneeCity'>
								{t('COMMON.CITY')}
							</label>
							<input
								id='consigneeCity'
								name='consigneeCity'
								type='text'
								className='form-item__field'
								placeholder='City'
								maxLength='70'
								value={consigneeDetails.city ? consigneeDetails.city.toUpperCase() : ''}
								onChange={(e) => {
									consigneeDetails.city = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										consigneeDetails: consigneeDetails,
									}))
								}}
								onBlur={(e) => {
									validateRequired(
										e.target.value,
										setError,
										'consigneeCity',
										t('COMMON.CITY')
									)
								}}
								aria-invalid={hasError('consigneeCity', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeCity']
									? t(error['consigneeCity'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='consigneeState'>
								{t('COMMON.STATE')}
							</label>
							<input
								id='consigneeState'
								name='consigneeState'
								type='text'
								className='form-item__field'
								placeholder='State'
								maxLength='70'
								value={consigneeDetails.state ? consigneeDetails.state.toUpperCase() : ''}
								onChange={(e) => {
									consigneeDetails.state = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										consigneeDetails: consigneeDetails,
									}))
								}}
								onBlur={(e) => {
									validateAlphaIfPresent(
										e.target.value,
										setError,
										'consigneeState'
									)
								}}
								aria-invalid={hasError('consigneeState', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeState']
									? t(error['consigneeState'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className={`col-12 form-item form-item--text ${hasError('consigneeCountry', error) ? 'error-field' : ''
								}`}
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='consigneeCountry'>
								{t('COMMON.CNTRY')}
							</label>
							<CountryAutocomplete
								autoCompleteType={AUTOCOMPLETE_TYPE_COUNTRY.CONSIGNEE}
								selectedShipperCountry={shipperDetails.countryCode}
								selectedCosigneeCountry={consigneeDetails.countryCode}
								setSelectedCosigneeCountry={setSelectedConsigneeCountry}
								isMandatory={true}
								setError={setError}
							/>
							{/*<input
								id='consigneeCountry'
								name='consigneeCountry'
								type='text'
								className='form-item__field'
								placeholder='Country'
								value={consigneeDetails.countryCode}
								onChange={(e) => {
									consigneeDetails.countryCode = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										consigneeDetails: consigneeDetails,
									}))
								}}
								onBlur={(e) => {
									validateAlphaRequired(
										e.target.value,
										setError,
										'consigneeCountry'
									)
								}}
								aria-invalid={hasError('consigneeCountry', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>*/}
							<span className='form-item__error'>
								{error && error['consigneeCountry']
									? t(error['consigneeCountry'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='consigneeZipCode'>
								{t('COMMON.ZIP_CODE')}
							</label>
							<input
								id='consigneeZipCode'
								name='consigneeZipCode'
								type='text'
								className='form-item__field'
								placeholder='Zip code'
								maxLength='11'
								value={consigneeDetails.zipCode ? consigneeDetails.zipCode.toUpperCase() : ''}
								onChange={(e) => {
									consigneeDetails.zipCode = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										consigneeDetails: consigneeDetails,
									}))
								}}
								onBlur={(e) => {
									validateZipCodeIfPresent(
										e.target.value,
										setError,
										'consigneeZipCode'
									)
								}}
								aria-invalid={hasError('consigneeZipCode', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeZipCode']
									? t(error['consigneeZipCode'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label
								className='form-item__label'
								htmlFor='consigneeAccountNumber'
							>
								{t('COMMON.ACCNT_NO')}
							</label>
							<input
								id='consigneeAccountNumber'
								name='consigneeAccountNumber'
								type='text'
								className='form-item__field'
								placeholder='Account number'
								maxLength='14'
								value={consigneeDetails.accountNumber ? consigneeDetails.accountNumber.toUpperCase() : ''}
								onChange={(e) => {
									consigneeDetails.accountNumber = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										consigneeDetails: consigneeDetails,
									}))
								}}
								onBlur={(e) => {
									validateNumericIfPresent(
										e.target.value,
										setError,
										'consigneeAccountNumber'
									)
								}}
								aria-invalid={hasError('consigneeAccountNumber', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeAccountNumber']
									? t(error['consigneeAccountNumber'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>

					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='consigneeTelephone'>
								{t('COMMON.TEL_HEAD')}
							</label>
							<input
								id='consigneeTelephone'
								name='consigneeTelephone'
								type='text'
								className='form-item__field'
								placeholder='Telephone'
								maxLength='20'
								value={consigneeDetails.telephoneNo ? consigneeDetails.telephoneNo.toUpperCase() : ''}
								onChange={(e) => {
									consigneeDetails.telephoneNo = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										consigneeDetails: consigneeDetails,
									}))
								}}
								onBlur={(e) => {
									const status = validatePhoneNumberIfPresent(
										e.target.value,
										setError,
										'consigneeTelephone'
									)
									if (e.target.value !== '' && status) {
										setError((prevState) => ({
											...prevState,
											notifyConsignee: '',
										}))
									}
								}}
								aria-invalid={hasError('consigneeTelephone', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeTelephone']
									? t(error['consigneeTelephone'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>

					<div className='form-row mt-md-4 mt-2 mb-md-3 mb-2'>
						<div
							className='col-12 form-item form-item--text'
							data-module='molecules/form-item/FormItem'
						>
							<label className='form-item__label' htmlFor='consigneeEmail'>
								{t('COMMON.EMAIL_HEAD')}
							</label>
							<input
								id='consigneeEmail'
								name='consigneeEmail'
								type='text'
								className='form-item__field'
								placeholder='Email address'
								maxLength='50'
								value={consigneeDetails.emailAddress ? consigneeDetails.emailAddress.toUpperCase() : ''}
								onChange={(e) => {
									consigneeDetails.emailAddress = e.target.value
									setAwbDetails((prevState) => ({
										...prevState,
										consigneeDetails: consigneeDetails,
									}))
								}}
								onBlur={(e) => {
									const status = validateEmailIfPresent(
										e.target.value,
										setError,
										'consigneeEmail'
									)
									if (e.target.value !== '' && status) {
										setError((prevState) => ({
											...prevState,
											notifyConsignee: '',
										}))
									}
								}}
								aria-invalid={hasError('consigneeEmail', error)}
								onFocus={(e) => {
									removeError(e, setError)
								}}
							/>
							<span className='form-item__error'>
								{error && error['consigneeEmail']
									? t(error['consigneeEmail'], { ns: 'errors' })
									: ''}
							</span>
						</div>
					</div>
				</div>
			</div>
			{!showNotifyList && (
				<div className='form-row mb-3 mt-3'>
					<div className='col-12 col-lg-8'>
						<button
							className='link'
							style={{ background: 'transparent' }}
							onClick={handleShowNotificationDetails}
						>
							{t('COMMON.NOTIFICATION')}
						</button>
					</div>
				</div>
			)}
			{showNotifyList && (
				<div className='notification-details'>
				<div className='form-row'>
						<div className='col-12 col-lg-8'>
							<h5 className='section-sub-heading pl-md-1 pl-0 mb-1'>
								{t('CAPTURE_AWB.NOTIFY')}
							</h5>
						</div>
					</div>
						{/*<div className='form-row mb-3 mt-3'>
						<div className='col-12 col-lg-8'>
							<div className='form-row mt-3 pl-1'>
								<div className='col col-lg-auto custom-pr-5'>
									<div
										className='form-item form-item--checkbox '
										data-module='molecules/form-item/FormItem'
									>
										<input
											id='notifyShipper'
											checked={notifyList.notifyShipper.checked}
											value={notifyList.notifyShipper.checked}
											type='checkbox'
											name='notifyShipper'
											className='form-item__field'
											onChange={handleNotify}
										/>
										<label htmlFor='notifyShipper' className='form-item__label'>
											{t('CAPTURE_AWB.SHIPPER')}
										</label>
									</div>
								</div>
								<div className='col col-lg-auto pl-2 custom-pr-5'>
									<div
										className='form-item form-item--checkbox '
										data-module='molecules/form-item/FormItem'
									>
										<input
											id='notifyConsignee'
											checked={notifyList.notifyConsignee.checked}
											value={notifyList.notifyConsignee.checked}
											type='checkbox'
											name='notifyConsignee'
											className='form-item__field'
											onChange={handleNotify}
										/>
										<label
											htmlFor='notifyConsignee'
											className='form-item__label'
										>
											{t('CAPTURE_AWB.CONSIGNEE')}
										</label>
									</div>
								</div>
								<div className='col col-lg-auto pl-2 custom-pr-5'>
									<div
										className='form-item form-item--checkbox '
										data-module='molecules/form-item/FormItem'
									>
										<input
											id='notifyOther'
											checked={notifyList.notifyOther.checked}
											value={notifyList.notifyOther.checked}
											type='checkbox'
											name='notifyOther'
											className='form-item__field'
											onChange={handleNotify}
										/>
										<label htmlFor='notifyOther' className='form-item__label'>
											{t('CAPTURE_AWB.OTHER')}
										</label>
									</div>
								</div>
							</div>
							<div>
								<span className='form-item__error'>
									{error && error['notifyList']
										? t(error['notifyList'], { ns: 'errors' })
										: ''}
								</span>
								<span className='form-item__error'>
									{error && error['notifyShipper']
										? t(error['notifyShipper'], { ns: 'errors' })
										: ''}
								</span>
								<span className='form-item__error'>
									{error && error['notifyConsignee']
										? t(error['notifyConsignee'], { ns: 'errors' })
										: ''}
								</span>
							</div>
						</div>
			</div>*/}
					{notifyList && notifyList.notifyOther.checked && (
						<div className='notify-other-details mb-2'>
							{/*<div className='form-row mb-3 mt-3'>
								<div className='col-12 col-lg-8'>
									<h5 className='section-sub-heading pl-md-1 pl-0 mb-3'>
										{t('COMMON.DTLS')}
									</h5>
								</div>
							</div>*/}
							<div className='form-row mt-3 mb-1'>
								<div
									className='col-12 col-lg-4 form-item form-item--text'
									data-module='molecules/form-item/FormItem'
								>
									<label className='form-item__label'>{t('COMMON.NAME')}</label>
									<input
										id='notifyName'
										name='notifyName'
										type='text'
										className='form-item__field'
										placeholder='Name'
										maxLength='70'
										value={notifyList.notifyOther.name ? notifyList.notifyOther.name.toUpperCase() : '' }
										onChange={(e) => {
											let notifyOther = notifyList.notifyOther
											notifyOther.name = e.target.value
											setNotifyList((prevState) => ({
												...prevState,
												notifyOther: notifyOther,
											}))
										}}
										onBlur={(e) => {
											validateAlphaIfPresent(
												e.target.value,
												setError,
												'notifyName'
											)
										}}
										aria-invalid={hasError('notifyName', error)}
										onFocus={(e) => {
											removeError(e, setError)
										}}
									/>
									<span className='form-item__error'>
										{error && error['notifyName']
											? t(error['notifyName'], { ns: 'errors' })
											: ''}
									</span>
								</div>
								<div
									className='col-12 col-lg-4 form-item form-item--text'
									data-module='molecules/form-item/FormItem'
								>
									<label className='form-item__label'>
										{t('COMMON.TEL_PLACEHOLDER')}
									</label>
									<input
										id='notifyTelephone'
										name='notifyTelephone'
										type='text'
										className='form-item__field'
										placeholder='Telephone'
										maxLength='20'
										value={notifyList.notifyOther.telephoneNo ? notifyList.notifyOther.telephoneNo.toUpperCase() : ''}
										onChange={(e) => {
											let notifyOther = notifyList.notifyOther
											notifyOther.telephoneNo = e.target.value
											setNotifyList((prevState) => ({
												...prevState,
												notifyOther: notifyOther,
											}))
										}}
										onBlur={(e) => {
											validatePhoneNumberIfPresent(
												e.target.value,
												setError,
												'notifyTelephone'
											)
										}}
										aria-invalid={hasError('notifyTelephone', error)}
										onFocus={(e) => {
											removeError(e, setError)
										}}
									/>
									<span className='form-item__error'>
										{error && error['notifyTelephone']
											? t(error['notifyTelephone'], { ns: 'errors' })
											: ''}
									</span>
								</div>
								<div
									className='col-12 col-lg-4 form-item form-item--text'
									data-module='molecules/form-item/FormItem'
								>
									<label className='form-item__label'>
										{t('COMMON.EMAIL_PLACEHOLDER')}
									</label>
									<input
										id='notifyEmail'
										name='notifyEmail'
										type='text'
										className='form-item__field'
										placeholder='Email address'
										maxLength='50'
										value={notifyList.notifyOther.email ? notifyList.notifyOther.email.toUpperCase() : ''}
										onChange={(e) => {
											let notifyOther = notifyList.notifyOther
											notifyOther.email = e.target.value
											setNotifyList((prevState) => ({
												...prevState,
												notifyOther: notifyOther,
											}))
										}}
										onBlur={(e) => {
											validateEmailIfPresent(
												e.target.value,
												setError,
												'notifyEmail'
											)
										}}
										aria-invalid={hasError('notifyEmail', error)}
										onFocus={(e) => {
											removeError(e, setError)
										}}
									/>
									<span className='form-item__error'>
										{error && error['notifyEmail']
											? t(error['notifyEmail'], { ns: 'errors' })
											: ''}
									</span>
								</div>
								</div>
								<div className='form-row mt-4'>
									<div
										className='col-12 col-lg-4 form-item form-item--text'
										data-module='molecules/form-item/FormItem'
									>
										<label className='form-item__label' htmlFor='notifyOtherAddress'>
											{t('COMMON.ADDRS')}
										</label>
										<input
											id='notifyOtherAddress'
											name='notifyOtherAddress'
											type='text'
											className='form-item__field'
											placeholder='Address'
											maxLength='70'
											value={notifyList.notifyOther.address ? notifyList.notifyOther.address.toUpperCase() : ''}
											onChange={(e) => {
												let notifyOther = notifyList.notifyOther
												notifyOther.address = e.target.value
												setNotifyList((prevState) => ({
													...prevState,
													notifyOther: notifyOther,
												}))
											}}
											onBlur={(e) => {
												validateAddressIfPresent(
													e.target.value,
													setError,
													'notifyOtherAddress'
												)
											}}
											aria-invalid={hasError('notifyOtherAddress', error)}
											onFocus={(e) => {
												removeError(e, setError)
											}}
										/>
										<span className='form-item__error'>
											{error && error['notifyOtherAddress']
												? t(error['notifyOtherAddress'], { ns: 'errors' })
												: ''}
										</span>
									</div>
									<div
										className='col-12 col-lg-4 form-item form-item--text'
										data-module='molecules/form-item/FormItem'
									>
										<label className='form-item__label' htmlFor='shipperCity'>
											{t('COMMON.CITY')}
										</label>
										<input
											id='notifyOtherCity'
											name='notifyOtherCity'
											type='text'
											className='form-item__field'
											placeholder='City'
											value={notifyList.notifyOther.city ? notifyList.notifyOther.city.toUpperCase() : ''}
											maxLength='70'
											onChange={(e) => {
												let notifyOther = notifyList.notifyOther
												notifyOther.city = e.target.value
												setNotifyList((prevState) => ({
													...prevState,
													notifyOther: notifyOther,
												}))
											}}
											onBlur={(e) => {
												validateAlphaIfPresent(e.target.value, setError, 'notifyOtherCity')
											}}
											aria-invalid={hasError('notifyOtherCity', error)}
											onFocus={(e) => {
												removeError(e, setError)
											}}
										/>
										<span className='form-item__error'>
											{error && error['notifyOtherCity']
												? t(error['notifyOtherCity'], { ns: 'errors' })
												: ''}
										</span>
									</div>
									<div
										className={`col-12 col-lg-4 form-item form-item--text ${hasError('notifyOtherCountry', error) ? 'error-field' : ''
											}`}
										data-module='molecules/form-item/FormItem'
									>
										<label className='form-item__label' htmlFor='notifyOtherCountry'>
											{t('COMMON.CNTRY')}
										</label>
										<CountryAutocomplete
											autoCompleteType={AUTOCOMPLETE_TYPE_COUNTRY.CONSIGNEE}
											selectedShipperCountry={notifyList.notifyOther.country}
											selectedCosigneeCountry={notifyList.notifyOther.country}
											setSelectedCosigneeCountry={setSelectedNotifyListOtherCountry}
											isMandatory={false}
											setError={setError}
											fieldName='notifyOtherCountry'
										/>
										<span className='form-item__error'>
											{error && error['notifyOtherCountry']
												? t(error['notifyOtherCountry'], { ns: 'errors' })
												: ''}
										</span>
									</div>
								</div>

							<div>
								<span className='form-item__error'>
									{error && error['notifyOther']
										? t(error['notifyOther'], { ns: 'errors' })
										: ''}
								</span>
							</div>
						</div>
					)}
				</div>
			)}
			<div className='pt-2 pb-3 section-seperator'>
				<hr />
			</div>
		</div>
	)
}

export default ParticipantDetails
